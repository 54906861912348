import { Icon } from '@ui5/webcomponents-react';
import { Link } from 'react-router-dom';
import TitleBanner from '../sectionComponents/title-banner';

import "@ui5/webcomponents-icons/dist/arrow-right.js";
import '../../styles/pages/common-page.css';

const ImpressumPage = () => {
	return (
		<>
		<TitleBanner title="Impressum" />
		<div className="common-page">
			<div className="common-page-box">
				<p><span className="common-text-bold">Angaben gemäß § 5 TMG</span></p>
				<p>
					Entdeckerland gGmbH<br />
					Lortzingstr. 13<br />
					68799 Reilingen<br />
					mobil: +49 15560 352298<br />
					<a href="mailto:irina.trauter@entdeckerland-kita.de">
						irina.trauter@entdeckerland-kita.de
					</a><br />
					Registergericht: Mannheim<br />
					HRB-Nummer: 749582

				</p>
				<p>
					<span className="common-text-bold">Vertreten durch</span><br />
					Irina Trauter und Arthur Trauter<br />
					Lortzingstraße 13<br />
					68799 Reilingen<br />
				</p>
				<p>
					<span className="common-text-bold">Leitung</span><br />
					Irina Trauter<br />
					mobil: +49 15560 352298<br />
					<a href="mailto:irina.trauter@entdeckerland-kita.de">
						irina.trauter@entdeckerland-kita.de
					</a>
				</p>
				<h1>Kontakt</h1>
				<p>
					<Icon name="arrow-right" />&nbsp;&nbsp;
					<Link to="/contact">Kontaktdaten</Link>
				</p>
			</div>
		</div>
		</>
	);
}

export default ImpressumPage;