import { Icon } from '@ui5/webcomponents-react';
import { Link } from 'react-router-dom';

import "@ui5/webcomponents-icons/dist/arrow-right.js";
import '../../styles/pages/common-page.css';
import '../../styles/pages/news-page.css';
import TitleBanner from '../sectionComponents/title-banner';

const NewsPage = () => {
	const PathToInfomappe = "./static/media/Infomappe_072024.pdf";

	return (
		<>
		<TitleBanner title="Aktuelles" />
		<div className="common-page">
			<div className="common-page-box">
				<h1 id="news-page-timetable">Es ist endlich so weit!</h1>
				<div className="news-box-2-rows">
					<h2>Liebe Eltern, liebe Interessierte,</h2>
					<p>
						wir freuen uns sehr, Ihnen mitteilen zu können, dass unsere neue Kindertagesstätte seit dem 2. September ihre Türen geöffnet hat! 🎉
					</p>
					<p>
						Unsere Einrichtung bietet eine liebevolle und sichere Umgebung, in der Ihre Kinder spielen, lernen und wachsen können.
						Wir legen großen Wert auf individuelle Förderung und ein harmonisches Miteinander.
					</p>
					<p>
						Wir laden Sie herzlich ein, unsere Räumlichkeiten zu besichtigen und unser Team kennenzulernen. Sprechen Sie uns einfach auf einen Besichtigungstermin an.
						Bei Fragen stehen wir Ihnen jederzeit zur Verfügung.
					</p>
					<p>
						Herzliche Grüße,<br />
						Ihr Team der Kindertagesstätte
					</p>
				</div>
			</div>
			<div className="common-page-box">
				<h1>Die Renovierungsarbeiten</h1>
				<div className="news-box-2-rows">
					<p>
						Die letzten 2 Wochen im August waren ziemlich intensiv. Wir haben die recht kurze Zeit genutzt, um Ihren Kindern eine schöne
						und sichere Umgebung zu schaffen in der sie sich wohlfühlen können. Hier eine kleine Übersicht der Veränderungen auf die Sie sich freuen können:
					</p>
					<h2>Garten</h2>
					<ul>
						<li>Spielhaus im Garten hat einen neuen Anstrich erhalten</li>
						<li>Spielgerät mit Rutsche glänzt ebenfalls in neuen Farben</li>
						<li>Der Sandkasten ist neu ausgekleidet und hat 2250kg frischen Sand bekommen</li>
						<li>Die Spielfläche um das Spielhaus ist mit frischem Fallschutz in Form von Holzhackschnitzeln aufgefüllt</li>
						<li>Wir haben den Efeu aus dem Garten verbannt, so dass die Grünflächen nun sauber und für die Kinder bespielbar sind</li>
						<li>Wir haben auch eine neue Fläche im Garten geschaffen wo in naher Zukunft eine Box aufgestellt
							wird die als Garage für die Spielfahrzeuge dienen soll</li>
						<li>und einiges mehr</li>
					</ul>
					<h2>Innenräume</h2>
					<ul>
						<li>In der schönen Remise wurden die verputzten Wände neu gestrichen</li>
						<li>Der schöne Holzboden ist neu geschliffen und versiegelt worden</li>
						<li>Wir haben einen Großputz gemacht und die Möbel umgestellt</li>
						<li>Die Kinder dürfen sich auf eine neue Leseecke, Mal- und Bastelbereich und eine Bau- und Konstruktionsecke freuen</li>
						<li>Im Spieleregal finden sich viele neue Spiele und Puzzle</li>
						<li>Neue Möbel sind bestellt und werden nach und nach einige alte Möbel ersetzen</li>
						<li>und einiges mehr</li>
					</ul>
					<h2>Impressionen</h2>
					<p>
						Wir haben für Sie ein paar Bilder hochgeladen <br />
						<Link to="/kita" className="common-text-red common-text-bold">Unsere Kita</Link>
					</p>
				</div>
			</div>
			<div className="common-page-box">
				<h1 id="news-page-timetable">Roadmap zur Eröffnung der Kindertagesstätte</h1>
				<div className="news-box-2-rows">
					<ul className="news-box-header-list">
						<li><span className="news-box-header-list-span">seit Di. 09 Juli 2024</span>
							<span>Anbindung an das zentrale Anmeldungssystem. <br />System ist offen für <Link  className="common-text-blue common-text-bold" to="/registration">Anmeldungen</Link></span></li>
						<li><span className="news-box-header-list-span">Fr. 09 Aug. 2024</span>Letzter Tag der Betreuung vom Träger Dreikäsehoch</li>
						<li><span className="news-box-header-list-span">Do. 15 Aug. 2024</span>Geplante Schlüsselübergabe an Entdeckerland</li>
						<li><span className="news-box-header-list-span-central"><Icon name="arrow-right" /></span>Renovierungsarbeiten in der Einrichtung</li>
						<li><span className="news-box-header-list-span">Mo. 02 Sept. 2024</span>Eröffnung der Einrichtung&nbsp;<span className="common-text-green common-text-bold">Entdeckerland</span></li>
					</ul>
				</div>
			</div>

			<div className="common-page-box">
				<h1>Infoveranstaltung für Eltern und Interessierte (am 11. Juli 2024)</h1>
				<div className="news-box-2-rows news-box-bg-image">
					<div>
						<h2>Liebe Eltern,</h2>
						<p>
							Wir möchten allen Eltern herzlich Danken, die an unserer Infoveranstaltung am <span className="common-text-bold">Donnerstag 11ten. Juli</span> teilgenommen haben. 
							Das Wetter hatte mitgespielt und so hatten wir einen schönen Abend im Garten des Kindergartens.
							Wir haben die Gelegenheit genutzt uns gegenseitig etwas kennenzulernen und unser Konzept vorzustellen.
							Auch für Fragen haben wir ausreichend Zeit gefunden.
						</p>
						<p>
							Falls Sie an dem Abend keine Zeit gefunden haben uns zu Besuchen haben wir eine kurze Übersicht der Themen für Sie vorbereitet:
						</p>
						<ul className="news-box-header-list">
							<li><span className="news-box-header-list-span">Mitarbeiter</span> Wir haben das Glück bereits zwei ausgebilde Fachkräfte in Vollzeit für unsere Einrichtung gewinnen zu können. Diese werden von mir, Irina Trauter, pädagogisch untertützt. Somit sind wir fürs erste personell voll besetzt.</li>
							<li><span className="news-box-header-list-span">Zeitplan</span> Siehe Abschnitt&nbsp;<a href="#news-page-timetable" className="common-link-green">Wie geht es weiter?</a></li>
							<li><span className="news-box-header-list-span">Unser Konzept</span>Siehe&nbsp;<a href={PathToInfomappe} download="Infomappe_072024.pdf" className="common-text-orange common-text-bold">Infomappe</a></li>
							<li><span className="news-box-header-list-span">Vorschullprogramm</span></li>
							<li><span className="news-box-header-list-span">Anmeldungssystem</span> <span>Ihr Kind ist bereits in der Einrichtung Dreikäsehoch und Sie möchten in der Einrichtung bleiben? Dann brauchen Sie sich nur noch einmal zentral vormerken zu lassen. <br />
								Informationen dazu finden Sie auf der Seite <Link to="/registration" className="common-text-blue common-text-bold">Anmeldung</Link></span></li>
							<li><span className="news-box-header-list-span">Renovierung</span> Klar! Wir halten Sie auf dem laufenden sobald die Arbeiten starten.</li>
							<li><span className="news-box-header-list-span">Sprachförderung</span> Wir werden ein individuelles Sprachförderprogramm zusammen mit unseren Fachkräften erarbeiten.</li>
						</ul>
						<p>
							Es hat uns sehr viel Spaß gemacht, und wir freuen uns darauf, Ihre Kinder und Sie ab September in unserer Einrichtung begrüßen zu dürfen.
						</p>
						<br />
						<p className="common-page-signature">
							Irina Trauter
						</p>
					</div>
				</div>
				{/* <h1>Herzlich Willkommen!</h1>
				<p>
					Diese Seite befindet sich noch im Aufbau<br />
					Hier werden Sie unsere Aktuellen Themen vorfinden! <br />
					Diese wird beinhalten: <br />
				</p>
				<ul>
					<li>Essensplan</li>
					<li>Termine und Schließtage</li>
					<li>Stellenausschreibung</li>
				</ul> */}
			</div>
			<div className="common-page-box">
				<h1>Ihre Infomappe</h1>
				<div className="news-box-2-rows">
					<p>
						Wir haben für Sie eine Informationsmappe zusammengestellt mit den wichtigsten Infos zum pädagogischen Konzept, 
						Eingewöhnungskonzept, Vorschullprogramm und Kontaktinformationen zu Ihrem <span className="common-text-green common-text-bold">Entdeckerland</span>.
					</p>
					<p>
						<a href={PathToInfomappe} download="Infomappe_072024.pdf" className="common-text-header-2 common-text-orange">Infomappe zum Download</a>
					</p>
				</div>
			</div>
		</div>
		</>
	);
};

export default NewsPage;