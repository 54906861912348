import '../../styles/pages/common-page.css';
import ImageCarousel from '../sectionComponents/image-carousel';
import SectionQuote from '../sectionComponents/section-quote';
import TitleBanner from '../sectionComponents/title-banner';

// const images = importAll(require.context('../../resources', false, /\.(png|jpe?g|svg)$/));

const KitaPage = () => {
	return (
		<>
		<TitleBanner title="Unsere KiTa" />
		<div className="common-page">
			<div className="common-page-box">
				<h1>Herzlich Willkommen!</h1>
				<SectionQuote
					quote="Statt Kinder wissen zu lehren, statt Ihnen beizubringen, was sie lernen sollen, was Erwachsene festgelegt haben, statt sie so zu formen, wie das dem Bild des Erwachsenen entspricht, kommt es darauf an, Kinder kennen zu lernen, sich ein Bild von ihren Fähigkeiten zu machen und darauf aufbauend Impulse zur Unterstützung der Neugier und des Forschergeistes von Kindern zu finden."
					author="- Kornelia Schneider -"
				/>
			</div>
			<div className="common-page-box">
				<h2>Reise ins Entdeckerland :D</h2>
				<p></p>
				<ImageCarousel />
			</div>
			<div className="common-page-box">
				<h2>Unsere Öffnungszeiten</h2>
				<ul>
					<li className="common-page-list-type-none">7:00 - 14:00 / Verlängerte Öffnungszeiten</li>
					<li className="common-page-list-type-none">7:00 - 16:00 / Ganztags</li>
					<li className="common-page-list-type-none">Die Bringzeiten sind flexibel</li>	
				</ul>
			</div>
			<div className="common-page-box">
				<p>
					Diese Seite befindet sich noch im Aufbau<br />
					Wir freuen uns Ihnen bald mehr von uns zu zeigen! <br />
					Sie können sich freuen auf: <br />
				</p>
				<ul>
					<li>Unser Team</li>
					<li>Unser Elternbeirat</li>
				</ul>
			</div>
		</div>
		</>
	);
};

export default KitaPage;