import '../../styles/pages/common-page.css';
import TitleBanner from '../sectionComponents/title-banner';

const ContactPage = () => {
	return (
		<>
		<TitleBanner title="Kontakt" />
		<div className="common-page">
			<div className="common-page-box">
				<h1>Sie haben Fragen?</h1>
				<p>Wir danken Ihnen für Ihr Interesse. <br />
				So können Sie uns am schnellsten erreichen: <br /><br />
	
					mobil: +49 15560 352298<br />
					<a href="mailto:irina.trauter@entdeckerland-kita.de">
						irina.trauter@entdeckerland-kita.de
					</a>
				</p>
			</div>
			<div className="common-page-box">
				<h1>Sie möchten uns besuchen?</h1>
				<p>
					Vereinbaren Sie gerne einen Termin. Wir würden uns freuen Ihnen unsere Einrichtung zu zeigen.<br /><br />
					Entdeckerland gGmbH<br />
					Heiliggeiststr. 17<br />
 					69117 Heidelberg<br />
				</p>
			</div>
		</div>
		</>
	);
};

export default ContactPage;