import { Outlet } from "react-router-dom";
import Footbar from "./ui/main-footbar";
import MainNavbar from "./ui/main-navbar-bootstrap";
import PageFooter from "./ui/page-footer";

import './styles/App.css';

function App() {
	return (
		<div className="App">
			<MainNavbar></MainNavbar>
			<Outlet />
			<PageFooter></PageFooter>
			<Footbar></Footbar>
		</div>
	);
}

export default App;
