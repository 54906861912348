import '../../styles/sectionComponents/section-quote.css';
const SectionQuote = ({ quote, author }) => {
	return (
		<div className="common-page-center-alignment">
			<div className="section-quote">
				<p className="section-quote-quote">{quote}</p>
				<p className="section-quote-author">{author}</p>
			</div>
		</div>
	);
};
export default SectionQuote;