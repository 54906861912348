import { Icon } from '@ui5/webcomponents-react';
import { Link } from 'react-router-dom';
import logo from '../../resources/Logo-512.rechteck.rainbow.png';

import "@ui5/webcomponents-icons/dist/add.js";
import '../../styles/pages/common-page.css';
import '../../styles/pages/home-page.css';

export default function Home() {
	return (
	<>
		<header className="home-header">
			<img src={logo} className="home-header-logo" alt="logo" />
			<div className="home-header-box">
				<h2>Entdeckerland KiTa</h2>
				<p key={"header-subtitle-1"}></p>
			</div>
		</header>
		<div className="home-page">
			<div className="common-page-box home-page-alignment">
				<h2>Herzlich Willkommen!</h2>
				<p>
					Schön, dass Sie uns gefunden haben.
					Wir möchten Sie auf der Homepage der KiTa Entdeckerland herzlich willkommen heißen.
				</p>
				<p>
					<span className="common-text-bold common-text-green">Es ist endlich soweit!</span> <br/>Seit dem 2ten. September diesen Jahres haben wir mit einer neuen Trägerschaft in der
					<span className="common-text-bold"> Heiliggeiststraße 17 in Heidelberg</span> gestartet.
					Wir freuen uns sehr, Sie und Ihre Kinder in unserer neuen Kindergarteneinrichtung begrüßen zu dürfen. Inmitten der historischen Altstadt, haben wir einen Ort geschaffen,
					an dem Ihre Kinder spielen, lernen und sich entwickeln können. Wir laden Sie herzlich ein, unsere Einrichtung zu besuchen und sich selbst ein Bild von unserem Angebot zu machen.
					Lassen Sie uns gemeinsam die ersten Schritte in eine aufregende und lehrreiche Kindergartenzeit gehen.
				</p>
				{/* <p>
					Es hat uns gefreut einige von Ihnen auf der Informationsveranstaltung am 11. Juli kennengelernt zu haben.
					Wir haben auf der Seite <span><Link  className="common-link-green common-text-bold" to="/news">Aktuelles</Link></span> eine Zusammenfassung der besprochenen Themen hinterlegt. 
				</p> */}
				<p>
					Für Fragen stehen wir Ihnen jederzeit gerne zur Verfügung.
					<br /><Link className="common-text-bold common-text-lila" to="/contact">Sprechen sie uns an!</Link>
				</p>
				<p>	
					Herzlichen Dank für Ihr Interesse!
				</p>
				<p className="common-page-signature">
					Irina Trauter
				</p>
				<p> Geschäftsführung und <br />pädagogische Leitung</p>
				<p><Icon name="add" /></p>
				<p className="common-page-signature">
					Arthur Trauter
				</p>
				<p> Stv. Geschäftsführung</p>
			</div>
		</div>
	</>
	);
}