import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

import imgLeseecke from '../../resources/fotos/Leseecke.jpg';
import image2 from '../../resources/fotos/Foto2.jpg';
import image3 from '../../resources/fotos/Foto3.jpg';
import image4 from '../../resources/fotos/Foto4.jpg';

import '../../styles/sectionComponents/image-carousel.css';

// // Dynamically import all images from the resources directory
// const importAll = (r) => r.keys().map(r);
// const images = importAll(require.context('../../resources', false, /\.(png|jpe?g|svg)$/));

const ImageCarousel = () => {
	return (
		<div className="carousel-box-centered">
			<Carousel className="carousel">
				<Carousel.Item>
					<img
						src={imgLeseecke}
						className="carousel-image"
						alt="First slide"
					/>
					<Carousel.Caption>
						<h3>Die Leseecke</h3>
						<p> - ein Ort, an dem Ihre Kinder in die wunderbare Welt der Bücher eintauchen können. Unsere Leseecke ist mehr als nur ein Raum;
							sie ist ein gemütlicher Rückzugsort, dafür geschaffen, die Fantasie und Lesefreude Ihrer Kinder zu fördern.</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={image2}
						className="carousel-image"
						alt="second slide"
					/>
					<Carousel.Caption>
						<h3>Mal- und Bastelbereich</h3>
						<p> – ein Ort, an dem Ihre Kinder ihrer Kreativität freien Lauf lassen können. Hier können die Kleinen malen, basteln und gestalten, während sie spielerisch ihre motorischen
							Fähigkeiten und ihr künstlerisches Denken entwickeln.</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={image3}
						className="carousel-image"
						alt="third slide"
					/>
					<Carousel.Caption>
						<h3>Der Garten</h3>
						<p>
						– ein Ort, an dem Ihre Kinder spielen, entdecken und sich austoben können. Unser Garten bietet eine Vielzahl an Spielmöglichkeiten,
						die speziell darauf ausgelegt sind, die motorischen Fähigkeiten und die Kreativität der Kinder zu fördern.
						</p>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img
						src={image4}
						className="carousel-image"
						alt="fourth slide"
					/>
					<Carousel.Caption>
						<h3>Aus der Sicht der Kinder</h3>
						<p>
							Hier können die Kinder in einer sicheren und anregenden Umgebung spielen, lernen und wachsen. Wir laden Sie herzlich ein, unser Gartengelände zu besuchen und sich selbst
							ein Bild von diesem besonderen Ort mitten in der Heidelberger Altstatt zu machen.
						</p>
					</Carousel.Caption>
				</Carousel.Item>
			</Carousel>
		</div>
	);
};

export default ImageCarousel;