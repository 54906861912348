import { Link } from 'react-router-dom';
import '../styles/page-footer.css';

const PageFooter = () => {
	return (
		<div className="App-footer App-footer-bg-0">
			<footer className="footer-box">
				<div className="footer-column">
					<h3>Kontakt</h3>
					<address>
						<p>
							Entdeckerland gGmbH<br />
							{/* Heiliggeiststr. 17<br />
							69117 Heidelberg<br /> */}
						</p>
					</address>
					<h4>Unsere Leitung</h4>
					<p>
						Irina Trauter<br />
						mobil: +49 15560 352298<br />
						<a href="mailto:irina.trauter@entdeckerland-kita.de">
							irina.trauter@entdeckerland-kita.de
						</a>
					</p>
				</div>
				<div className="footer-column">
					<h3>Öffnungszeiten</h3>
					<p>
						Verl. Öffnungszeiten: 7:00 - 14:00<br />
						Ganztags: 7:00 - 16:00<br />
						Bringzeiten: flexibel<br />
					</p>
				</div>
				<div className="footer-column">
					<h3>Kurzmenü</h3>
					<p>
						<Link to="/">Home</Link><br />
						<Link to="/kita">Unsere Kita</Link><br />
						<Link to="/concept">Konzept</Link><br />
						<Link to="/news">Aktuelles</Link><br />
						<Link to="/contact">Kontakt</Link><br />
					</p>
				</div>
			</footer>
		</div>
	);
};

export default PageFooter;