import '../../styles/pages/common-page.css';
import TitleBanner from '../sectionComponents/title-banner';

const ConceptPage = () => {
	return (
		<>
		<TitleBanner title="Konzeption" />
		<div className="common-page">
			<div className="common-page-box">
				<h1>Unser Leitbild</h1>
				<div className="common-page-grid-flex-box">
					<div>
						<h2>Kinder</h2>
						<p>
							Mit unserem Betreuungs- und Bildungsangebot wollen wir den Kindern einen guten Start in die soziale Gemeinschaft ermöglichen. In unserer Kindertagesstätte möchten wir Kinder dazu befähigen, zu eigenverantwortlichen, gemeinschaftsfähigen und starken Menschen zu reifen.
						</p>
					</div>
					<div>
						<h2>Eltern</h2>
						<p>
							Dabei legen wir großen Wert, dass Eltern Familie und Beruf vereinbaren können. Wir wollen für alle Kinder offen sein und jedes Kind individuell begleiten und fördern können. Offenheit, Vertrauen und Wertschätzung kennzeichnen unsere Bildungspartnerschaft mit den Familien aus. Unsere Einrichtung ist für Familien mit Kindern im Alter von 3 Jahren bis zum Schuleintritt offen.  
						</p>
					</div>
					<div>
						<h2>Pädagogik</h2>
						<p>
							Das Fundament, auf dem unsere pädagogische Arbeit basiert, beinhalten vier wichtigen Säulen <span className="common-text-lila common-text-bold">Ko-Konstruktion</span>, <span className="common-text-blue common-text-bold">Partizipation</span>, <span className="common-text-green common-text-bold">Alltagsorientierte Pädagogik</span> und <span className="common-text-orange common-text-bold">Bildungspartnerschaft</span>. Wir möchten den Kindern Vertrauen, Sicherheit und emotionalen Halt geben und somit eine verlässliche Basis schaffen, um Bildungsprozesse zu ermöglichen. Es ist uns wichtig, jedes Kind in seiner Einzigartigkeit wahrzunehmen und ihm in einer anregungsreichen Umgebung zu ermöglichen, selbsttätig und in seinem eigenen Tempo zu entdecken. Gemeinsam mit Kindern und ihren Familien entdecken wir forschend die Welt. Kinder und pädagogische Fachkräfte sind an Entscheidungsprozessen beteiligt und gestalten den Kita-Alltag mit. 
						</p>
					</div>
					<div>
						<h2>Werte</h2>
						<p>
							Grundlegende Werte, die unser Miteinander im Alltag prägen, sind Respekt, Echtheit und Einfühlungsvermögen.
							Eine positive Atmosphäre in unserem Haus ist uns sehr wichtig und soll jedem vermitteln, dass er willkommen ist.
						</p>
					</div>
					<div>
						<h2>Mitarbeiter*innen</h2>
						<p>
							Unsere Mitarbeiterinnen und Mitarbeiter sind unser wichtigstes Kapital. Sie sind diejenigen, die die Kinder in ihrer Entwicklung begleiten und fördern. Wir legen großen Wert auf eine gute Arbeitsatmosphäre und regelmäßige Fortbildungen.
						</p>
					</div>
					{/* <ul>
						<li>Leitbild</li>
						<li>Schwerpunkte</li>
						<li>Eingewöhnung</li>
					</ul> */}
				</div>
			</div>
		</div>
		</>
	);
};

export default ConceptPage;