import '../../styles/pages/common-page.css';
import TitleBanner from '../sectionComponents/title-banner';

const DataProtectionPage = () => {
	return (
		<>
		<TitleBanner title="Datenschutzerklärung" />
		<div className="common-page">
			<div className="common-page-box">
				
				{/* <h1 className="adsimple-312817351">Datenschutzerklärung</h1> */}
				<h2 id="einleitung-ueberblick" className="adsimple-312817351">Einleitung und Überblick</h2>
				<p>Wir haben diese Datenschutzerklärung (Fassung 16.06.2024-312817351) verfasst, um Ihnen gemäß der Vorgaben der <a className="adsimple-312817351" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312817351#d1e2269-1-1" target="_blank" rel="noopener">Datenschutz-Grundverordnung (EU) 2016/679</a> und anwendbaren nationalen Gesetzen zu erklären, welche personenbezogenen Daten (kurz Daten) wir als Verantwortliche &#8211; und die von uns beauftragten Auftragsverarbeiter (z. B. Provider) &#8211; verarbeiten, zukünftig verarbeiten werden und welche rechtmäßigen Möglichkeiten Sie haben. Die verwendeten Begriffe sind geschlechtsneutral zu verstehen.<br />
				<strong className="adsimple-312817351">Kurz gesagt:</strong> Wir informieren Sie umfassend über Daten, die wir über Sie verarbeiten.</p>
				<p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch und verwenden juristische Fachbegriffe. Diese Datenschutzerklärung soll Ihnen hingegen die wichtigsten Dinge so einfach und transparent wie möglich beschreiben. Soweit es der Transparenz förderlich ist, werden technische <strong className="adsimple-312817351">Begriffe leserfreundlich erklärt</strong>, Links zu weiterführenden Informationen geboten und <strong className="adsimple-312817351">Grafiken</strong> zum Einsatz gebracht. Wir informieren damit in klarer und einfacher Sprache, dass wir im Rahmen unserer Geschäftstätigkeiten nur dann personenbezogene Daten verarbeiten, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich, wenn man möglichst knappe, unklare und juristisch-technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um Datenschutz geht. Ich hoffe, Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
				Wenn trotzdem Fragen bleiben, möchten wir Sie bitten, sich an die unten bzw. im Impressum genannte verantwortliche Stelle zu wenden, den vorhandenen Links zu folgen und sich weitere Informationen auf Drittseiten anzusehen. Unsere Kontaktdaten finden Sie selbstverständlich auch im Impressum.</p>
				<h2 id="anwendungsbereich" className="adsimple-312817351">Anwendungsbereich</h2>
				<p>Diese Datenschutzerklärung gilt für alle von uns im Unternehmen verarbeiteten personenbezogenen Daten und für alle personenbezogenen Daten, die von uns beauftragte Firmen (Auftragsverarbeiter) verarbeiten. Mit personenbezogenen Daten meinen wir Informationen im Sinne des Art. 4 Nr. 1 DSGVO wie zum Beispiel Name, E-Mail-Adresse und postalische Anschrift einer Person. Die Verarbeitung personenbezogener Daten sorgt dafür, dass wir unsere Dienstleistungen und Produkte anbieten und abrechnen können, sei es online oder offline. Der Anwendungsbereich dieser Datenschutzerklärung umfasst:</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">alle Onlineauftritte (Websites, Onlineshops), die wir betreiben</li>
				<li className="adsimple-312817351">Social Media Auftritte und E-Mail-Kommunikation</li>
				<li className="adsimple-312817351">mobile Apps für Smartphones und andere Geräte</li>
				</ul>
				<p>
				<strong className="adsimple-312817351">Kurz gesagt:</strong> Die Datenschutzerklärung gilt für alle Bereiche, in denen personenbezogene Daten im Unternehmen über die genannten Kanäle strukturiert verarbeitet werden. Sollten wir außerhalb dieser Kanäle mit Ihnen in Rechtsbeziehungen eintreten, werden wir Sie gegebenenfalls gesondert informieren.</p>
				<h2 id="rechtsgrundlagen" className="adsimple-312817351">Rechtsgrundlagen</h2>
				<p>In der folgenden Datenschutzerklärung geben wir Ihnen transparente Informationen zu den rechtlichen Grundsätzen und Vorschriften, also den Rechtsgrundlagen der Datenschutz-Grundverordnung, die uns ermöglichen, personenbezogene Daten zu verarbeiten.<br />
				Was das EU-Recht betrifft, beziehen wir uns auf die VERORDNUNG (EU) 2016/679 DES EUROPÄISCHEN PARLAMENTS UND DES RATES vom 27. April 2016. Diese Datenschutz-Grundverordnung der EU können Sie selbstverständlich online auf EUR-Lex, dem Zugang zum EU-Recht, unter <a className="adsimple-312817351" href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679">https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679</a> nachlesen.</p>
				<p>Wir verarbeiten Ihre Daten nur, wenn mindestens eine der folgenden Bedingungen zutrifft:</p>
				<ol>
				<li className="adsimple-312817351">
				<strong className="adsimple-312817351">Einwilligung</strong> (Artikel 6 Absatz 1 lit. a DSGVO): Sie haben uns Ihre Einwilligung gegeben, Daten zu einem bestimmten Zweck zu verarbeiten. Ein Beispiel wäre die Speicherung Ihrer eingegebenen Daten eines Kontaktformulars.</li>
				<li className="adsimple-312817351">
				<strong className="adsimple-312817351">Vertrag</strong> (Artikel 6 Absatz 1 lit. b DSGVO): Um einen Vertrag oder vorvertragliche Verpflichtungen mit Ihnen zu erfüllen, verarbeiten wir Ihre Daten. Wenn wir zum Beispiel einen Kaufvertrag mit Ihnen abschließen, benötigen wir vorab personenbezogene Informationen.</li>
				<li className="adsimple-312817351">
				<strong className="adsimple-312817351">Rechtliche Verpflichtung</strong> (Artikel 6 Absatz 1 lit. c DSGVO): Wenn wir einer rechtlichen Verpflichtung unterliegen, verarbeiten wir Ihre Daten. Zum Beispiel sind wir gesetzlich verpflichtet Rechnungen für die Buchhaltung aufzuheben. Diese enthalten in der Regel personenbezogene Daten.</li>
				<li className="adsimple-312817351">
				<strong className="adsimple-312817351">Berechtigte Interessen</strong> (Artikel 6 Absatz 1 lit. f DSGVO): Im Falle berechtigter Interessen, die Ihre Grundrechte nicht einschränken, behalten wir uns die Verarbeitung personenbezogener Daten vor. Wir müssen zum Beispiel gewisse Daten verarbeiten, um unsere Website sicher und wirtschaftlich effizient betreiben zu können. Diese Verarbeitung ist somit ein berechtigtes Interesse.</li>
				</ol>
				<p>Weitere Bedingungen wie die Wahrnehmung von Aufnahmen im öffentlichen Interesse und Ausübung öffentlicher Gewalt sowie dem Schutz lebenswichtiger Interessen treten bei uns in der Regel nicht auf. Soweit eine solche Rechtsgrundlage doch einschlägig sein sollte, wird diese an der entsprechenden Stelle ausgewiesen.</p>
				<p>Zusätzlich zu der EU-Verordnung gelten auch noch nationale Gesetze:</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">In <strong className="adsimple-312817351">Österreich</strong> ist dies das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten (<strong className="adsimple-312817351">Datenschutzgesetz</strong>), kurz <strong className="adsimple-312817351">DSG</strong>.</li>
				<li className="adsimple-312817351">In <strong className="adsimple-312817351">Deutschland</strong> gilt das <strong className="adsimple-312817351">Bundesdatenschutzgesetz</strong>, kurz<strong className="adsimple-312817351"> BDSG</strong>.</li>
				</ul>
				<p>Sofern weitere regionale oder nationale Gesetze zur Anwendung kommen, informieren wir Sie in den folgenden Abschnitten darüber.</p>
				<h2 id="kontaktdaten-verantwortliche" className="adsimple-312817351">Kontaktdaten des Verantwortlichen</h2>
				<p>Sollten Sie Fragen zum Datenschutz oder zur Verarbeitung personenbezogener Daten haben, finden Sie nachfolgend die Kontaktdaten der verantwortlichen Person bzw. Stelle:<br />
				<span className="adsimple-312817351" style={{"fontWeight": 400}}>Entdeckerland gGmbH<br />
				Arthur Trauter<br />
				Lortzingstr. 13, 68799 Reilingen, Deutschland</span>
				</p>
				<p>E-Mail: <a href="mailto:arthur.trauter@entdeckerland-kita.de">arthur.trauter@entdeckerland-kita.de</a>
				<br />
				Telefon: <a href="tel:+49 15560 352298">+49 15560 352298</a>
				<br />
				Impressum: <a href="https://www.entdeckerland-kita.de/impressum/">https://www.entdeckerland-kita.de/impressum/</a>
				</p>
				<h2 id="speicherdauer" className="adsimple-312817351">Speicherdauer</h2>
				<p>Dass wir personenbezogene Daten nur so lange speichern, wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist, gilt als generelles Kriterium bei uns. Das bedeutet, dass wir personenbezogene Daten löschen, sobald der Grund für die Datenverarbeitung nicht mehr vorhanden ist. In einigen Fällen sind wir gesetzlich dazu verpflichtet, bestimmte Daten auch nach Wegfall des ursprüngliches Zwecks zu speichern, zum Beispiel zu Zwecken der Buchführung.</p>
				<p>Sollten Sie die Löschung Ihrer Daten wünschen oder die Einwilligung zur Datenverarbeitung widerrufen, werden die Daten so rasch wie möglich und soweit keine Pflicht zur Speicherung besteht, gelöscht.</p>
				<p>Über die konkrete Dauer der jeweiligen Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben.</p>
				<h2 id="rechte-dsgvo" className="adsimple-312817351">Rechte laut Datenschutz-Grundverordnung</h2>
				<p>Gemäß Artikel 13, 14 DSGVO informieren wir Sie über die folgenden Rechte, die Ihnen zustehen, damit es zu einer fairen und transparenten Verarbeitung von Daten kommt:</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">Sie haben laut Artikel 15 DSGVO ein Auskunftsrecht darüber, ob wir Daten von Ihnen verarbeiten. Sollte das zutreffen, haben Sie Recht darauf eine Kopie der Daten zu erhalten und die folgenden Informationen zu erfahren:
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">zu welchem Zweck wir die Verarbeitung durchführen;</li>
				<li className="adsimple-312817351">die Kategorien, also die Arten von Daten, die verarbeitet werden;</li>
				<li className="adsimple-312817351">wer diese Daten erhält und wenn die Daten an Drittländer übermittelt werden, wie die Sicherheit garantiert werden kann;</li>
				<li className="adsimple-312817351">wie lange die Daten gespeichert werden;</li>
				<li className="adsimple-312817351">das Bestehen des Rechts auf Berichtigung, Löschung oder Einschränkung der Verarbeitung und dem Widerspruchsrecht gegen die Verarbeitung;</li>
				<li className="adsimple-312817351">dass Sie sich bei einer Aufsichtsbehörde beschweren können (Links zu diesen Behörden finden Sie weiter unten);</li>
				<li className="adsimple-312817351">die Herkunft der Daten, wenn wir sie nicht bei Ihnen erhoben haben;</li>
				<li className="adsimple-312817351">ob Profiling durchgeführt wird, ob also Daten automatisch ausgewertet werden, um zu einem persönlichen Profil von Ihnen zu gelangen.</li>
				</ul>
				</li>
				<li className="adsimple-312817351">Sie haben laut Artikel 16 DSGVO ein Recht auf Berichtigung der Daten, was bedeutet, dass wir Daten richtig stellen müssen, falls Sie Fehler finden.</li>
				<li className="adsimple-312817351">Sie haben laut Artikel 17 DSGVO das Recht auf Löschung („Recht auf Vergessenwerden“), was konkret bedeutet, dass Sie die Löschung Ihrer Daten verlangen dürfen.</li>
				<li className="adsimple-312817351">Sie haben laut Artikel 18 DSGVO das Recht auf Einschränkung der Verarbeitung, was bedeutet, dass wir die Daten nur mehr speichern dürfen aber nicht weiter verwenden.</li>
				<li className="adsimple-312817351">Sie haben laut Artikel 20 DSGVO das Recht auf Datenübertragbarkeit, was bedeutet, dass wir Ihnen auf Anfrage Ihre Daten in einem gängigen Format zur Verfügung stellen.</li>
				<li className="adsimple-312817351">Sie haben laut Artikel 21 DSGVO ein Widerspruchsrecht, welches nach Durchsetzung eine Änderung der Verarbeitung mit sich bringt.
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">Wenn die Verarbeitung Ihrer Daten auf Artikel 6 Abs. 1 lit. e (öffentliches Interesse, Ausübung öffentlicher Gewalt) oder Artikel 6 Abs. 1 lit. f (berechtigtes Interesse) basiert, können Sie gegen die Verarbeitung Widerspruch einlegen. Wir prüfen danach so rasch wie möglich, ob wir diesem Widerspruch rechtlich nachkommen können.</li>
				<li className="adsimple-312817351">Werden Daten verwendet, um Direktwerbung zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Direktmarketing verwenden.</li>
				<li className="adsimple-312817351">Werden Daten verwendet, um Profiling zu betreiben, können Sie jederzeit gegen diese Art der Datenverarbeitung widersprechen. Wir dürfen Ihre Daten danach nicht mehr für Profiling verwenden.</li>
				</ul>
				</li>
				<li className="adsimple-312817351">Sie haben laut Artikel 22 DSGVO unter Umständen das Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung (zum Beispiel Profiling) beruhenden Entscheidung unterworfen zu werden.</li>
				<li className="adsimple-312817351">Sie haben laut Artikel 77 DSGVO das Recht auf Beschwerde. Das heißt, Sie können sich jederzeit bei der Datenschutzbehörde beschweren, wenn Sie der Meinung sind, dass die Datenverarbeitung von personenbezogenen Daten gegen die DSGVO verstößt.</li>
				</ul>
				<p>
				<strong className="adsimple-312817351">Kurz gesagt:</strong> Sie haben Rechte &#8211; zögern Sie nicht, die oben gelistete verantwortliche Stelle bei uns zu kontaktieren!</p>
				<p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche in sonst einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. Diese ist für Österreich die Datenschutzbehörde, deren Website Sie unter <a className="adsimple-312817351" href="https://www.dsb.gv.at/?tid=312817351" target="_blank" rel="noopener">https://www.dsb.gv.at/</a> finden. In Deutschland gibt es für jedes Bundesland einen Datenschutzbeauftragten. Für nähere Informationen können Sie sich an die <a className="adsimple-312817351" href="https://www.bfdi.bund.de/DE/Home/home_node.html" target="_blank" rel="noopener">Bundesbeauftragte für den Datenschutz und die Informationsfreiheit (BfDI)</a> wenden. Für unser Unternehmen ist die folgende lokale Datenschutzbehörde zuständig:</p>
				<h2 id="baden-wuerttemberg-datenschutzbehoerde" className="adsimple-312817351">Baden-Württemberg Datenschutzbehörde</h2>
				<p>
				<strong className="adsimple-312817351">Landesbeauftragter für Datenschutz:</strong> Prof. Dr. Tobias Keber<br />
				<strong className="adsimple-312817351">Adresse:</strong> Lautenschlagerstraße 20, 70173 Stuttgart<br />
				<strong className="adsimple-312817351">Telefonnr.:</strong> 07 11/61 55 41-0<br />
				<strong className="adsimple-312817351">E-Mail-Adresse:</strong> <a className="adsimple-312817351" href="mailto:poststelle@lfdi.bwl.de">poststelle@lfdi.bwl.de</a>
				<br />
				<strong className="adsimple-312817351">Website:</strong> <a className="adsimple-312817351" href="https://www.baden-wuerttemberg.datenschutz.de/?tid=312817351" target="_blank" rel="noopener">https://www.baden-wuerttemberg.datenschutz.de/</a>
				</p>
				<h2 id="sicherheit-datenverarbeitung" className="adsimple-312817351">Sicherheit der Datenverarbeitung</h2>
				<p>Um personenbezogene Daten zu schützen, haben wir sowohl technische als auch organisatorische Maßnahmen umgesetzt. Wo es uns möglich ist, verschlüsseln oder pseudonymisieren wir personenbezogene Daten. Dadurch machen wir es im Rahmen unserer Möglichkeiten so schwer wie möglich, dass Dritte aus unseren Daten auf persönliche Informationen schließen können.</p>
				<p>Art. 25 DSGVO spricht hier von &#8220;Datenschutz durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen&#8221; und meint damit, dass man sowohl bei Software (z. B. Formularen) also auch Hardware (z. B. Zugang zum Serverraum) immer an Sicherheit denkt und entsprechende Maßnahmen setzt. Im Folgenden gehen wir, falls erforderlich, noch auf konkrete Maßnahmen ein.</p>
				<h2 id="tls-verschluesselung-https" className="adsimple-312817351">TLS-Verschlüsselung mit https</h2>
				<p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“), um Daten abhörsicher im Internet zu übertragen.<br />
				Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist &#8211; niemand kann &#8220;mithören&#8221;.</p>
				<p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen den Datenschutz durch Technikgestaltung (<a className="adsimple-312817351" href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&amp;from=DE&amp;tid=312817351" target="_blank" rel="noopener">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet, können wir den Schutz vertraulicher Daten sicherstellen.<br />
				Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https.svg" width="17" height="18" /> links oben im Browser, links von der Internetadresse (z. B. beispielseite.de) und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
				Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach &#8220;Hypertext Transfer Protocol Secure wiki&#8221; um gute Links zu weiterführenden Informationen zu erhalten.</p>
				<h2 id="kommunikation" className="adsimple-312817351">Kommunikation</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Kommunikation Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Alle, die mit uns per Telefon, E-Mail oder Online-Formular kommunizieren<br />
				&#x1f4d3; Verarbeitete Daten: z. B. Telefonnummer, Name, E-Mail-Adresse, eingegebene Formulardaten. Mehr Details dazu finden Sie bei der jeweils eingesetzten Kontaktart<br />
				&#x1f91d; Zweck: Abwicklung der Kommunikation mit Kunden, Geschäftspartnern usw.<br />
				&#x1f4c5; Speicherdauer: Dauer des Geschäftsfalls und der gesetzlichen Vorschriften<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. b DSGVO (Vertrag), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
				</tr>
				</tbody>
				</table>
				<p>Wenn Sie mit uns Kontakt aufnehmen und per Telefon, E-Mail oder Online-Formular kommunizieren, kann es zur Verarbeitung personenbezogener Daten kommen.</p>
				<p>Die Daten werden für die Abwicklung und Bearbeitung Ihrer Frage und des damit zusammenhängenden Geschäftsvorgangs verarbeitet. Die Daten während eben solange gespeichert bzw. solange es das Gesetz vorschreibt.</p>
				<h3 className="adsimple-312817351">Betroffene Personen</h3>
				<p>Von den genannten Vorgängen sind alle betroffen, die über die von uns bereit gestellten Kommunikationswege den Kontakt zu uns suchen.</p>
				<h3 className="adsimple-312817351">Telefon</h3>
				<p>Wenn Sie uns anrufen, werden die Anrufdaten auf dem jeweiligen Endgerät und beim eingesetzten Telekommunikationsanbieter pseudonymisiert gespeichert. Außerdem können Daten wie Name und Telefonnummer im Anschluss per E-Mail versendet und zur Anfragebeantwortung gespeichert werden. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
				<h3 className="adsimple-312817351">E-Mail</h3>
				<p>Wenn Sie mit uns per E-Mail kommunizieren, werden Daten gegebenenfalls auf dem jeweiligen Endgerät (Computer, Laptop, Smartphone,&#8230;) gespeichert und es kommt zur Speicherung von Daten auf dem E-Mail-Server. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
				<h3 className="adsimple-312817351">Online Formulare</h3>
				<p>Wenn Sie mit uns mittels Online-Formular kommunizieren, werden Daten auf unserem Webserver gespeichert und gegebenenfalls an eine E-Mail-Adresse von uns weitergeleitet. Die Daten werden gelöscht, sobald der Geschäftsfall beendet wurde und es gesetzliche Vorgaben erlauben.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlagen</h3>
				<p>Die Verarbeitung der Daten basiert auf den folgenden Rechtsgrundlagen:</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">Art. 6 Abs. 1 lit. a DSGVO (Einwilligung): Sie geben uns die Einwilligung Ihre Daten zu speichern und weiter für den Geschäftsfall betreffende Zwecke zu verwenden;</li>
				<li className="adsimple-312817351">Art. 6 Abs. 1 lit. b DSGVO (Vertrag): Es besteht die Notwendigkeit für die Erfüllung eines Vertrags mit Ihnen oder einem Auftragsverarbeiter wie z. B. dem Telefonanbieter oder wir müssen die Daten für vorvertragliche Tätigkeiten, wie z. B. die Vorbereitung eines Angebots, verarbeiten;</li>
				<li className="adsimple-312817351">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen): Wir wollen Kundenanfragen und geschäftliche Kommunikation in einem professionellen Rahmen betreiben. Dazu sind gewisse technische Einrichtungen wie z. B. E-Mail-Programme, Exchange-Server und Mobilfunkbetreiber notwendig, um die Kommunikation effizient betreiben zu können.</li>
				</ul>
				<h2 id="cookies" className="adsimple-312817351">Cookies</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Cookies Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Besucher der Website<br />
				&#x1f91d; Zweck: abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
				&#x1f4d3; Verarbeitete Daten: Abhängig vom jeweils eingesetzten Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, der das Cookie setzt.<br />
				&#x1f4c5; Speicherdauer: abhängig vom jeweiligen Cookie, kann von Stunden bis hin zu Jahren variieren<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was sind Cookies?</h3>
				<p>Unsere Website verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.<br />
				Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</p>
				<p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Websites speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
				<p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Websites verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &#8220;Hirn&#8221; Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
				<p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
				<p>Die folgende Grafik zeigt eine mögliche Interaktion zwischen einem Webbrowser wie z. B. Chrome und dem Webserver. Dabei fordert der Webbrowser eine Website an und erhält vom Server ein Cookie zurück, welches der Browser erneut verwendet, sobald eine andere Seite angefordert wird.</p>
				<p>
				<img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/http-cookie-interaction.svg" alt="HTTP Cookie Interaktion zwischen Browser und Webserver" width="100%" />
				</p>
				<p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Websites (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.</p>
				<p>So können zum Beispiel Cookie-Daten aussehen:</p>
				<p>
				<strong className="adsimple-312817351">Name:</strong> _ga<br />
				<strong className="adsimple-312817351">Wert:</strong> GA1.2.1326744211.152312817351-9<br />
				<strong className="adsimple-312817351">Verwendungszweck:</strong> Unterscheidung der Websitebesucher<br />
				<strong className="adsimple-312817351">Ablaufdatum:</strong> nach 2 Jahren</p>
				<p>Diese Mindestgrößen sollte ein Browser unterstützen können:</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">Mindestens 4096 Bytes pro Cookie</li>
				<li className="adsimple-312817351">Mindestens 50 Cookies pro Domain</li>
				<li className="adsimple-312817351">Mindestens 3000 Cookies insgesamt</li>
				</ul>
				<h3 className="adsimple-312817351">Welche Arten von Cookies gibt es?</h3>
				<p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.</p>
				<p>Man kann 4 Arten von Cookies unterscheiden:</p>
				<p>
				<strong className="adsimple-312817351">Unerlässliche Cookies<br />
				</strong>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.</p>
				<p>
				<strong className="adsimple-312817351">Zweckmäßige Cookies<br />
				</strong>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern gemessen.</p>
				<p>
				<strong className="adsimple-312817351">Zielorientierte Cookies<br />
				</strong>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.</p>
				<p>
				<strong className="adsimple-312817351">Werbe-Cookies<br />
				</strong>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
				<p>Üblicherweise werden Sie beim erstmaligen Besuch einer Website gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
				<p>Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir <a className="adsimple-312817351" href="https://datatracker.ietf.org/doc/html/rfc6265">https://datatracker.ietf.org/doc/html/rfc6265</a>, dem Request for Comments der Internet Engineering Task Force (IETF) namens &#8220;HTTP State Management Mechanism&#8221;.</p>
				<h3 className="adsimple-312817351">Zweck der Verarbeitung über Cookies</h3>
				<p>Der Zweck ist letztendlich abhängig vom jeweiligen Cookie. Mehr Details dazu finden Sie weiter unten bzw. beim Hersteller der Software, die das Cookie setzt.</p>
				<h3 className="adsimple-312817351">Welche Daten werden verarbeitet?</h3>
				<p>Cookies sind kleine Gehilfen für eine viele verschiedene Aufgaben. Welche Daten in Cookies gespeichert werden, kann man leider nicht verallgemeinern, aber wir werden Sie im Rahmen der folgenden Datenschutzerklärung über die verarbeiteten bzw. gespeicherten Daten informieren.</p>
				<h3 className="adsimple-312817351">Speicherdauer von Cookies</h3>
				<p>Die Speicherdauer hängt vom jeweiligen Cookie ab und wird weiter unter präzisiert. Manche Cookies werden nach weniger als einer Stunde gelöscht, andere können mehrere Jahre auf einem Computer gespeichert bleiben.</p>
				<p>Sie haben außerdem selbst Einfluss auf die Speicherdauer. Sie können über ihren Browser sämtliche Cookies jederzeit manuell löschen (siehe auch unten &#8220;Widerspruchsrecht&#8221;). Ferner werden Cookies, die auf einer Einwilligung beruhen, spätestens nach Widerruf Ihrer Einwilligung gelöscht, wobei die Rechtmäßigkeit der Speicherung bis dahin unberührt bleibt.</p>
				<h3 className="adsimple-312817351">Widerspruchsrecht &#8211; wie kann ich Cookies löschen?</h3>
				<p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.</p>
				<p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
				<p>
				<a className="adsimple-312817351" href="https://support.google.com/chrome/answer/95647?tid=312817351" target="_blank" rel="noopener noreferrer">Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a>
				</p>
				<p>
				<a className="adsimple-312817351" href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=312817351" target="_blank" rel="noopener noreferrer">Safari: Verwalten von Cookies und Websitedaten mit Safari</a>
				</p>
				<p>
				<a className="adsimple-312817351" href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=312817351" target="_blank" rel="noopener noreferrer">Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a>
				</p>
				<p>
				<a className="adsimple-312817351" href="https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d?tid=312817351">Internet Explorer: Löschen und Verwalten von Cookies</a>
				</p>
				<p>
				<a className="adsimple-312817351" href="https://support.microsoft.com/de-de/microsoft-edge/cookies-in-microsoft-edge-l%C3%B6schen-63947406-40ac-c3b8-57b9-2a946a29ae09?tid=312817351">Microsoft Edge: Löschen und Verwalten von Cookies</a>
				</p>
				<p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder &#8220;Cookies deaktivieren Chrome&#8221; im Falle eines Chrome Browsers.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine <strong className="adsimple-312817351">Einwilligung</strong> (Artikel 6 Abs. 1 lit. a DSGVO) von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 165 Abs. 3 des Telekommunikationsgesetzes (2021). In Deutschland wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser Richtlinie weitgehend in § 15 Abs. 3 des Telemediengesetzes (TMG), welches seit Mai 2024 durch das Digitale-Dienste-Gesetz (DDG) ersetzt wurde.</p>
				<p>Für unbedingt notwendige Cookies, auch soweit keine Einwilligung vorliegt, bestehen <strong className="adsimple-312817351">berechtigte Interessen</strong> (Artikel 6 Abs. 1 lit. f DSGVO), die in den meisten Fällen wirtschaftlicher Natur sind. Wir möchten den Besuchern der Website eine angenehme Benutzererfahrung bescheren und dafür sind bestimmte Cookies oft unbedingt notwendig.</p>
				<p>Soweit nicht unbedingt erforderliche Cookies zum Einsatz kommen, geschieht dies nur im Falle Ihrer Einwilligung. Rechtsgrundlage ist insoweit Art. 6 Abs. 1 lit. a DSGVO.</p>
				<p>In den folgenden Abschnitten werden Sie genauer über den Einsatz von Cookies informiert, sofern eingesetzte Software Cookies verwendet.</p>
				<h2 id="bewerbungsdaten" className="adsimple-312817351">Bewerbungsdaten</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Bewerbungsdaten Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Nutzer, die sich bei uns für eine Arbeitsstelle bewerben<br />
				&#x1f91d; Zweck: Abwicklung eines Bewerbungsverfahrens<br />
				&#x1f4d3; Verarbeitete Daten: Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer, Qualifikationsnachweise (Zeugnisse), evtl. Daten besonderer Kategorien.<br />
				&#x1f4c5; Speicherdauer: bei erfolgreicher Bewerbung bis zum Ende des Dienstverhältnisses. Anderenfalls werden die Daten nach dem Bewerbungsverfahren gelöscht oder mit Ihrer Einwilligung für einen gewissen Zeitraum gespeichert.<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO), Art. 6 Abs 1 lit. b DSGVO (Vertrag), Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was sind Bewerbungsdaten?</h3>
				<p>Sie können sich bei uns per E-Mail, Online-Formular oder über ein Recruiting-Tool für eine Arbeitsstelle in unserem Unternehmen bewerben. Alle Daten, die wir im Rahmen einer Bewerbung von Ihnen erhalten und verarbeiten, zählen zu den Bewerbungsdaten. Dabei geben Sie immer auch personenbezogene Daten wie etwa Namen, Geburtsdatum, Adresse und Telefonnummer preis.</p>
				<h3 className="adsimple-312817351">Warum verarbeiten wir Bewerbungsdaten?</h3>
				<p>Wir verarbeiten Ihre Daten, damit wir ein ordentliches Auswahlverfahren in Bezug auf die ausgeschriebene Stelle betreiben können. Zusätzlich halten wir auch gerne Ihre Bewerbungsunterlagen in unserem Bewerbungsarchiv. Denn oft kommt es vor, dass für die ausgeschriebene Stellen eine Zusammenarbeit aus den unterschiedlichsten Gründen nicht klappt, wir aber von Ihnen und Ihrer Bewerbung beeindruckt sind und uns eine zukünftige Zusammenarbeit sehr gut vorstellen können. Sofern Sie uns dafür Ihre Einwilligung geben, archivieren wir Ihre Unterlagen, damit wir Sie für zukünftige Aufgaben in unserem Unternehmen leicht kontaktieren können.</p>
				<p>Wir garantieren Ihnen, dass wir besonders behutsam mit Ihren Daten umgehen und immer nur innerhalb des rechtlichen Rahmens Ihre Daten verarbeiten. Auch innerhalb unseres Unternehmens werden Ihre Daten nur an Personen weitergeleitet, die unmittelbar mit Ihrer Bewerbung zu tun haben. Kurz gesagt: Ihre Daten sind bei uns sicher aufgehoben!</p>
				<h3 className="adsimple-312817351">Welche Daten werden verarbeitet?</h3>
				<p>Wenn Sie sich zum Beispiel bei uns per E-Mail bewerben, erhalten wir natürlich, wie oben erwähnt, auch personenbezogene Daten. Selbst die E-Mail-Adresse zählt schon zu den personenbezogenen Daten. Verarbeitet werden im Zuge eines Bewerbungsverfahrens allerdings nur jene Daten, die für unsere Entscheidung, ob wir Sie in unserem Team begrüßen wollen oder nicht, relevant sind.</p>
				<p>Welche Daten genau verarbeitet werden, hängt in erster Linie von der Stellenausschreibung ab. Meistens handelt es sich aber um Namen, Geburtsdatum, Kontaktdaten und Qualifikationsnachweise. Wenn Sie die Bewerbung über ein Online-Formular einreichen, werden die Daten verschlüsselt an uns weitergegeben. Schicken Sie uns die Bewerbung per E-Mail, findet diese Verschlüsselung nicht statt. Für den Weg der Übertragung können wir somit keine Verantwortung übernehmen. Sobald die Daten aber auf unseren Servern sind, sind wir für die rechtmäßige Handhabung Ihrer Daten verantwortlich.</p>
				<p>Während eines Bewerbungsvorgangs können neben den oben genannten Daten auch Informationen zu Ihrer Gesundheit oder Ihrer ethnischen Herkunft angefragt werden, damit wir und Sie die Rechte in Bezug auf Arbeitsrecht, sozialer Sicherheit und Sozialschutz ausüben können und gleichzeitig den dazu entsprechenden Pflichten nachkommen können. Bei diesen Daten handelt es sich um Daten besonderer Kategorien.</p>
				<p>Hier eine Liste möglicher Daten, die wir von Ihnen erhalten und verarbeiten:</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">Name</li>
				<li className="adsimple-312817351">Kontaktadresse</li>
				<li className="adsimple-312817351">E-Mail-Adresse</li>
				<li className="adsimple-312817351">Telefonnummer</li>
				<li className="adsimple-312817351">Geburtsdatum</li>
				<li className="adsimple-312817351">Informationen, die aus Anschreiben und Lebenslauf hervorgehen</li>
				<li className="adsimple-312817351">Qualifikationsnachweise (z. B.) Zeugnisse</li>
				<li className="adsimple-312817351">Daten besonderer Kategorien (z. B. ethnische Herkunft, Gesundheitsdaten, religiöse Überzeugungen)</li>
				<li className="adsimple-312817351">Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
				<li className="adsimple-312817351">Metadaten (IP-Adresse, Geräte-Informationen)</li>
				</ul>
				<h3 className="adsimple-312817351">Wie lange werden die Daten gespeichert?</h3>
				<p>Wenn wir Sie als Teammitglied in unserem Unternehmen aufnehmen, werden Ihre Daten für den Zweck des Arbeitsverhältnisses weiterverarbeitet und mindestens bis zur Beendigung des Arbeitsverhältnisses bei uns aufbewahrt. Alle Bewerbungsunterlagen kommen dann in Ihre Mitarbeiterakte.</p>
				<p>Bieten wir Ihnen die Arbeitsstelle nicht an, lehnen Sie unser Angebot ab oder ziehen Ihre Bewerbung zurück, können wir aufgrund des berechtigten Interesses (Art. 6 Abs. 1 lit. f DSGVO) Ihre Daten bis zu 6 Monate nach Abschluss des Bewerbungsverfahrens aufbewahren. Danach werden sowohl Ihre elektronischen Daten als auch alle Daten aus physischen Bewerbungsunterlagen vollständig gelöscht bzw. vernichtet. Wir behalten uns Ihre Daten etwa, damit wir noch etwaige Nachfragen beantworten können oder, damit wir im Falle eines Rechtsstreits Nachweise über die Bewerbung vorlegen können. Falls sich ein Rechtsstreit anbahnt und wir eventuell die Daten nach Ablauf der 6 Monate immer noch benötigen, werden wir die Daten erst dann löschen, wenn es keinen Grund mehr zur Aufbewahrung gibt. Sofern es gesetzliche Aufbewahrungspflichten zu erfüllen gibt, müssen wir die Daten grundsätzlich länger als 6 Monate speichern.</p>
				<p>Weiters können wir Ihre Daten auch länger aufbewahren, wenn Sie dafür eine spezielle Einwilligung erteilt haben. Das machen wir zum Beispiel, wenn wir uns in Zukunft eine Zusammenarbeit mit Ihnen gut vorstellen können. Dann ist es hilfreich Ihre Daten archiviert zu haben, um Sie problemlos erreichen können. In diesem Fall kommen die Daten in unser Bewerberpool. Selbstverständlich können Sie Ihre Einwilligung zur längeren Aufbewahrung Ihrer Daten jederzeit widerrufen. Erfolgt kein Widerruf und geben Sie keine neue Einwilligung ab, werden Ihre Daten spätestens nach 2 Jahren gelöscht.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw. vorvertragliche Maßnahmen), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)  und Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien).</p>
				<p>Nehmen wir Sie in unser Bewerbertool auf, passiert dies auf der Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Wir weisen Sie darauf hin, dass Ihre Zustimmung in unser Bewerbungspool freiwillig ist, keinen Einfluss auf den Bewerbungsprozess hat und Sie jederzeit die Möglichkeit haben, Ihre Einwilligung zu widerrufen. Die Rechtmäßigkeit der Verarbeitung bis zum Zeitpunkt des Widerrufs bleibt davon unberührt.</p>
				<p>Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung gemäß Art. 9 Abs. 2 lit. c. DSGVO. Für Zwecke der Gesundheitsversorgung, der Arbeitsmedizin, für die medizinische Diagnostik, für die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten gemäß Art. 9 Abs. 2 lit. h. DSGVO. Wenn Sie freiwillig Daten der besonderen Kategorien mitteilen, erfolgt die Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.</p>
				<h2 id="kundendaten" className="adsimple-312817351">Kundendaten</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Kundendaten Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Kunden bzw. Geschäfts- und Vertragspartner<br />
				&#x1f91d; Zweck: Erbringung der vertraglich oder vorvertraglich vereinbarten Leistungen einschließlich zugehörige Kommunikation<br />
				&#x1f4d3; Verarbeitete Daten: Name, Adresse, Kontaktdaten, E-Mail-Adresse, Telefonnummer, Zahlungsinformationen (wie z. B. Rechnungen und Bankdaten), Vertragsdaten (wie z. B. Laufzeit und Gegenstand des Vertrags), IP-Adresse, Bestelldaten<br />
				&#x1f4c5; Speicherdauer: die Daten werden gelöscht, sobald sie zur Erbringung unserer geschäftlichen Zwecke nicht mehr erforderlich sind und es keine gesetzliche Aufbewahrungspflicht gibt.<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO), Vertrag (Art. 6 Abs 1 lit. b DSGVO)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was sind Kundendaten?</h3>
				<p>Damit wir unser Service bzw. unsere vertraglichen Leistungen anbieten können, verarbeiten wir auch Daten unserer Kunden und Geschäftspartner. Unter diesen Daten befinden sich immer auch personenbezogene Daten. Unter Kundendaten versteht man alle Informationen, die auf Basis einer vertraglichen oder vorvertraglichen Zusammenarbeit verarbeitet werden, um die angebotenen Leistungen erbringen zu können. Kundendaten sind also alle gesammelten Informationen, die wir über unsere Kunden erheben und verarbeiten.</p>
				<h3 className="adsimple-312817351">Warum verarbeiten wir Kundendaten?</h3>
				<p>Es gibt viele Gründe, warum wir Kundendaten sammeln und verarbeiten. Der wichtigste ist, dass wir zur Bereitstellung unserer Services einfach verschiedene Daten benötigen. Manchmal reicht hier schon Ihre E-Mail-Adresse, doch wenn Sie etwa ein Produkt oder eine Dienstleistung erwerben, benötigen wir auch Daten wie beispielsweise Name, Adresse, Bankdaten oder Vertragsdaten. Wir nutzen die Daten in weiterer Folge auch für Marketing- und Vertriebsoptimierungen, damit wir insgesamt unser Service für unsere Kunden verbessern können. Ein weiterer wichtiger Punkt ist auch unser Kundenservice, der uns stets sehr am Herzen liegt. Wir wollen, dass Sie mit Frage zu unseren Angeboten jederzeit zu uns kommen können und dafür brauchen wir zumindest Ihre E-Mail-Adresse.</p>
				<h3 className="adsimple-312817351">Welche Daten werden verarbeitet?</h3>
				<p>Welche Daten genau gespeichert werden, kann an dieser Stelle nur anhand Kategorien wiedergegeben werden. Dies hängt nämlich stets davon ab, welche Leistungen Sie von uns beziehen. In manchen Fällen geben Sie uns lediglich Ihre E-Mail-Adresse, damit wir beispielsweise mit Ihnen in Kontakt treten können oder wir Ihre Fragen beantworten können. In anderen Fällen erwerben Sie ein Produkt oder eine Dienstleistung bei uns und dafür benötigen wir deutlich mehr Informationen, wie etwa Ihre Kontaktdaten, Zahlungsdaten und Vertragsdaten.</p>
				<p>Hier eine Liste möglicher Daten, die wir von Ihnen erhalten und verarbeiten:</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">Name</li>
				<li className="adsimple-312817351">Kontaktadresse</li>
				<li className="adsimple-312817351">E-Mail-Adresse</li>
				<li className="adsimple-312817351">Telefonnummer</li>
				<li className="adsimple-312817351">Geburtsdatum</li>
				<li className="adsimple-312817351">Zahlungsdaten (Rechnungen, Bankdaten, Zahlungshistorie etc.)</li>
				<li className="adsimple-312817351">Vertragsdaten ( Laufzeit, Inhalt)</li>
				<li className="adsimple-312817351">Nutzungsdaten (besuchte Websites, Zugriffsdaten ect.)</li>
				<li className="adsimple-312817351">Metadaten (IP-Adresse, Geräte-Informationen)</li>
				</ul>
				<h3 className="adsimple-312817351">Wie lange werden die Daten gespeichert?</h3>
				<p>Sobald wir die Kundendaten zur Erfüllung unserer vertraglichen Pflichten und unserer Zwecke nicht mehr benötigen und die Daten auch nicht für mögliche Gewährleistungs- und Haftungspflichten nötig sind, löschen wir die entsprechenden Kundendaten. Das ist zum Beispiel der Fall, wenn ein geschäftlicher Vertrag endet. Danach beträgt die Verjährungsfrist in der Regel 3 Jahre, wobei längere Fristen im Einzelfall möglich sind. Wir halten uns natürlich auch an die gesetzlichen Aufbewahrungspflichten. Ihre Kundendaten werden ganz sicher nicht an Dritte weitergegeben, wenn Sie dazu nicht explizit eine Einwilligung erteilt haben.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Rechtsgrundlagen für die Verarbeitung Ihrer Daten sind Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs 1 lit. b DSGVO (Vertrag bzw. vorvertragliche Maßnahmen), Art. 6 Abs. 1 lit. f DSGVO (berechtigte Interessen)  und in speziellen Fällen (z. B. bei medizinischen Leistungen) Art. 9 Abs. 2 lit. a. DSGVO (Verarbeitung besonderer Kategorien).</p>
				<p>Für den Fall des Schutzes lebenswichtiger Interessen erfolgt die Datenverarbeitung gemäß Art. 9 Abs. 2 lit. c. DSGVO. Für Zwecke der Gesundheitsversorgung, der Arbeitsmedizin, für die medizinische Diagnostik, für die Versorgung oder Behandlung im Gesundheits- oder Sozialbereich oder für die Verwaltung von Systemen und Diensten im Gesundheits- oder Sozialbereich erfolgt die Verarbeitung personenbezogener Daten gemäß Art. 9 Abs. 2 lit. h. DSGVO. Wenn Sie freiwillig Daten der besonderen Kategorien mitteilen, erfolgt die Verarbeitung auf Grundlage von Art. 9 Abs. 2 lit. a. DSGVO.</p>
				<h2 id="webhosting-einleitung" className="adsimple-312817351">Webhosting Einleitung</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Webhosting Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Besucher der Website<br />
				&#x1f91d; Zweck: professionelles Hosting der Website und Absicherung des Betriebs<br />
				&#x1f4d3; Verarbeitete Daten: IP-Adresse, Zeitpunkt des Websitebesuchs, verwendeter Browser und weitere Daten. Mehr Details dazu finden Sie weiter unten bzw. beim jeweils eingesetzten Webhosting Provider.<br />
				&#x1f4c5; Speicherdauer: abhängig vom jeweiligen Provider, aber in der Regel 2 Wochen<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit.f DSGVO (Berechtigte Interessen)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was ist Webhosting?</h3>
				<p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen &#8211; auch personenbezogene Daten &#8211; automatisch erstellt und gespeichert, so auch auf dieser Website. Diese Daten sollten möglichst sparsam und nur mit Begründung verarbeitet werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf einer Domain, d.h. alles von der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum Beispiel beispiel.de oder musterbeispiel.com.</p>
				<p>Wenn Sie eine Website auf einem Computer, Tablet oder Smartphone ansehen möchten, verwenden Sie dafür ein Programm, das sich Webbrowser nennt. Sie kennen vermutlich einige Webbrowser beim Namen: Google Chrome, Microsoft Edge, Mozilla Firefox und Apple Safari. Wir sagen kurz Browser oder Webbrowser dazu.</p>
				<p>Um die Website anzuzeigen, muss sich der Browser zu einem anderen Computer verbinden, wo der Code der Website gespeichert ist: dem Webserver. Der Betrieb eines Webservers ist eine komplizierte und aufwendige Aufgabe, weswegen dies in der Regel von professionellen Anbietern, den Providern, übernommen wird. Diese bieten Webhosting an und sorgen damit für eine verlässliche und fehlerfreie Speicherung der Daten von Websites. Eine ganze Menge Fachbegriffe, aber bitte bleiben Sie dran, es wird noch besser!</p>
				<p>Bei der Verbindungsaufnahme des Browsers auf Ihrem Computer (Desktop, Laptop, Tablet oder Smartphone) und während der Datenübertragung zu und vom Webserver kann es zu einer Verarbeitung personenbezogener Daten kommen. Einerseits speichert Ihr Computer Daten, andererseits muss auch der Webserver Daten eine Zeit lang speichern, um einen ordentlichen Betrieb zu gewährleisten.</p>
				<p>Ein Bild sagt mehr als tausend Worte, daher zeigt folgende Grafik zur Veranschaulichung das Zusammenspiel zwischen Browser, dem Internet und dem Hosting-Provider.</p>
				<p>
				<img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver.svg" alt="Browser und Webserver" width="100%" />
				</p>
				<h3 className="adsimple-312817351">Warum verarbeiten wir personenbezogene Daten?</h3>
				<p>Die Zwecke der Datenverarbeitung sind:</p>
				<ol>
				<li className="adsimple-312817351">Professionelles Hosting der Website und Absicherung des Betriebs</li>
				<li className="adsimple-312817351">zur Aufrechterhaltung der Betriebs- und IT-Sicherheit</li>
				<li className="adsimple-312817351">Anonyme Auswertung des Zugriffsverhaltens zur Verbesserung unseres Angebots und ggf. zur Strafverfolgung bzw. Verfolgung von Ansprüchen</li>
				</ol>
				<h3 className="adsimple-312817351">Welche Daten werden verarbeitet?</h3>
				<p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver, das ist der Computer auf dem diese Webseite gespeichert ist, in der Regel automatisch Daten wie</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">die komplette Internetadresse (URL) der aufgerufenen Webseite</li>
				<li className="adsimple-312817351">Browser und Browserversion (z. B. Chrome 87)</li>
				<li className="adsimple-312817351">das verwendete Betriebssystem (z. B. Windows 10)</li>
				<li className="adsimple-312817351">die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B. <a className="adsimple-312817351" href="https://www.beispielquellsite.de/vondabinichgekommen/" target="_blank" rel="follow noopener">https://www.beispielquellsite.de/vondabinichgekommen/</a>)</li>
				<li className="adsimple-312817351">den Hostnamen und die IP-Adresse des Geräts von welchem aus zugegriffen wird (z. B. COMPUTERNAME und 194.23.43.121)</li>
				<li className="adsimple-312817351">Datum und Uhrzeit</li>
				<li className="adsimple-312817351">in Dateien, den sogenannten Webserver-Logfiles</li>
				</ul>
				<h3 className="adsimple-312817351">Wie lange werden Daten gespeichert?</h3>
				<p>In der Regel werden die oben genannten Daten zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten von Behörden eingesehen werden.</p>
				<p>
				<strong className="adsimple-312817351">Kurz gesagt:</strong> Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht ohne Zustimmung weiter!</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Die Rechtmäßigkeit der Verarbeitung personenbezogener Daten im Rahmen des Webhosting ergibt sich aus Art. 6 Abs. 1 lit. f DSGVO (Wahrung der berechtigten Interessen), denn die Nutzung von professionellem Hosting bei einem Provider ist notwendig, um das Unternehmen im Internet sicher und nutzerfreundlich präsentieren und Angriffe und Forderungen hieraus gegebenenfalls verfolgen zu können.</p>
				<p>Zwischen uns und dem Hosting-Provider besteht in der Regel ein Vertrag über die Auftragsverarbeitung gemäß Art. 28 f. DSGVO, der die Einhaltung von Datenschutz gewährleistet und Datensicherheit garantiert.</p>
				<h2 id="social-media-einleitung" className="adsimple-312817351">Social Media Einleitung</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Social Media Datenschutzerklärung Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Besucher der Website<br />
				&#x1f91d; Zweck: Darstellung und Optimierung unserer Serviceleistung, Kontakt zu Besuchern, Interessenten u.a., Werbung<br />
				&#x1f4d3; Verarbeitete Daten: Daten wie etwa Telefonnummern, E-Mail-Adressen, Kontaktdaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
				Mehr Details dazu finden Sie beim jeweils eingesetzten Social-Media-Tool.<br />
				&#x1f4c5; Speicherdauer: abhängig von den verwendeten Social-Media-Plattformen<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was ist Social Media?</h3>
				<p>Zusätzlich zu unserer Website sind wir auch in diversen Social-Media-Plattformen aktiv. Dabei können Daten von Usern verarbeitet werden, damit wir gezielt User, die sich für uns interessieren, über die sozialen Netzwerke ansprechen können. Darüber hinaus können auch Elemente einer Social-Media-Plattform direkt in unsere Website eingebettet sein. Das ist etwa der Fall, wenn Sie einen sogenannten Social-Button auf unserer Website anklicken und direkt zu unserem Social-Media-Auftritt weitergeleitet werden. Als sogenannte Sozialen Medien oder Social Media werden Websites und Apps bezeichnet, über die angemeldete Mitglieder Inhalte produzieren, Inhalte offen oder in bestimmten Gruppen austauschen und sich mit anderen Mitgliedern vernetzen können.</p>
				<h3 className="adsimple-312817351">Warum nutzen wir Social Media?</h3>
				<p>Seit Jahren sind Social-Media-Plattformen der Ort, wo Menschen online kommunizieren und in Kontakt treten. Mit unseren Social-Media-Auftritten können wir unsere Produkte und Dienstleistungen Interessenten näherbringen. Die auf unserer Website eingebundenen Social-Media-Elemente helfen Ihnen, schnell und ohne Komplikationen zu unseren Social-Media-Inhalten wechseln können.</p>
				<p>Die Daten, die durch Ihre Nutzung eines Social-Media-Kanals gespeichert und verarbeitet werden, haben in erster Linie den Zweck, Webanalysen durchführen zu können. Ziel dieser Analysen ist es, genauere und personenbezogene Marketing- und Werbestrategien entwickeln zu können. Abhängig von Ihrem Verhalten auf einer Social-Media-Plattform, können mit Hilfe der ausgewerteten Daten, passende Rückschlüsse auf Ihre Interessen getroffen werden und sogenannte Userprofile erstellt werden. So ist es den Plattformen auch möglich, Ihnen maßgeschneiderte Werbeanzeigen zu präsentieren. Meistens werden für diesen Zweck Cookies in Ihrem Browser gesetzt, die Daten zu Ihrem Nutzungsverhalten speichern.</p>
				<p>Wir gehen in der Regel davon aus, dass wir datenschutzrechtlich verantwortlich bleiben, auch wenn wir Dienste einer Social-Media-Plattform nutzen. Der Europäische Gerichtshof hat jedoch entschieden, dass in bestimmten Fällen der Betreiber der Social-Media-Plattform zusammen mit uns gemeinsam verantwortlich im Sinne des Art. 26 DSGVO sein kann. Soweit dies der Fall ist, weisen wir gesondert darauf hin und arbeiten auf Grundlage einer diesbezüglichen Vereinbarung. Das Wesentliche der Vereinbarung ist dann weiter unten bei der betroffenen Plattform wiedergegeben.</p>
				<p>Bitte beachten Sie, dass bei der Nutzung der Social-Media-Plattformen oder unserer eingebauten Elemente auch Daten von Ihnen außerhalb der Europäischen Union verarbeitet werden können, da viele Social-Media-Kanäle, beispielsweise Facebook oder Twitter, amerikanische Unternehmen sind. Dadurch können Sie möglicherweise Ihre Rechte in Bezug auf Ihre personenbezogenen Daten nicht mehr so leicht einfordern bzw. durchsetzen.</p>
				<h3 className="adsimple-312817351">Welche Daten werden verarbeitet?</h3>
				<p>Welche Daten genau gespeichert und verarbeitet werden, hängt vom jeweiligen Anbieter der Social-Media-Plattform ab. Aber für gewöhnlich handelt es sich um Daten wie etwa Telefonnummern, E-Mailadressen, Daten, die Sie in ein Kontaktformular eingeben, Nutzerdaten wie zum Beispiel welche Buttons Sie klicken, wen Sie liken oder wem folgen, wann Sie welche Seiten besucht haben, Informationen zu Ihrem Gerät und Ihre IP-Adresse. Die meisten dieser Daten werden in Cookies gespeichert. Speziell wenn Sie selbst ein Profil bei dem besuchten Social-Media-Kanal haben und angemeldet sind, können Daten mit Ihrem Profil verknüpft werden.</p>
				<p>Alle Daten, die über eine Social-Media-Plattform erhoben werden, werden auch auf den Servern der Anbieter gespeichert. Somit haben auch nur die Anbieter Zugang zu den Daten und können Ihnen die passenden Auskünfte geben bzw. Änderungen vornehmen.</p>
				<p>Wenn Sie genau wissen wollen, welche Daten bei den Social-Media-Anbietern gespeichert und verarbeitet werden und wie sie der Datenverarbeitung widersprechen können, sollten Sie die jeweilige Datenschutzerklärung des Unternehmens sorgfältig durchlesen. Auch wenn Sie zur Datenspeicherung und Datenverarbeitung Fragen haben oder entsprechende Rechte geltend machen wollen, empfehlen wir Ihnen, sich direkt an den Anbieter wenden.</p>
				<h3 className="adsimple-312817351">
				<span className="adsimple-312817351" data-sheets-value="{&quot;1&quot;:2,&quot;2&quot;:&quot;Wo und wie lange werden Daten gespeichert?&quot;}" data-sheets-userformat="{&quot;2&quot;:769,&quot;3&quot;:{&quot;1&quot;:0},&quot;11&quot;:4,&quot;12&quot;:0}">Dauer der Datenverarbeitung</span>
				</h3>
				<p>Über die Dauer der Datenverarbeitung informieren wir Sie weiter unten, sofern wir weitere Informationen dazu haben. Beispielsweise speichert die Social-Media-Plattform Facebook Daten, bis sie für den eigenen Zweck nicht mehr benötigt werden. Kundendaten, die mit den eigenen Userdaten abgeglichen werden, werden aber schon innerhalb von zwei Tagen gelöscht. Generell verarbeiten wir personenbezogene Daten nur so lange wie es für die Bereitstellung unserer Dienstleistungen und Produkte unbedingt notwendig ist. Wenn es, wie zum Beispiel im Fall von Buchhaltung, gesetzlich vorgeschrieben ist, kann diese Speicherdauer auch überschritten werden.</p>
				<h3 className="adsimple-312817351">Widerspruchsrecht</h3>
				<p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern wie eingebettete Social-Media-Elemente zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Zum Bespiel können Sie auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen.</p>
				<p>Da bei Social-Media-Tools Cookies zum Einsatz kommen können, empfehlen wir Ihnen auch unsere allgemeine Datenschutzerklärung über Cookies. Um zu erfahren, welche Daten von Ihnen genau gespeichert und verarbeitet werden, sollten Sie die Datenschutzerklärungen der jeweiligen Tools durchlesen.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong className="adsimple-312817351">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten bei Vorliegen einer Einwilligung auch auf Grundlage unseres berechtigten Interesses <strong className="adsimple-312817351">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
				<p>Informationen zu speziellen Social-Media-Plattformen erfahren Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
				<h2 id="facebook-datenschutzerklaerung" className="adsimple-312817351">Facebook Datenschutzerklärung</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Facebook Datenschutzerklärung Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Besucher der Website<br />
				&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
				&#x1f4d3; Verarbeitete Daten: Daten wie etwa Kundendaten, Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
				Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
				&#x1f4c5; Speicherdauer: bis die Daten für Facebooks Zwecke nicht mehr nützlich sind<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was sind Facebook-Tools?</h3>
				<p>Wir verwenden auf unserer Website ausgewählte Tools von Facebook. Facebook ist ein Social Media Network des Unternehmens Meta Platforms Inc. bzw. für den europäischen Raum des Unternehmens Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland. Mithilfe dieser Tools können wir Ihnen und Menschen, die sich für unsere Produkte und Dienstleistungen interessieren, das bestmögliche Angebot bieten.</p>
				<p>Wenn über unsere eingebetteten Facebook-Elemente oder über unsere Facebook-Seite (Fanpage) Daten von Ihnen erhoben und weitergeleitet werden, sind sowohl wir als auch Facebook Irland Ltd. dafür verantwortlich. Für die weitere Verarbeitung dieser Daten trägt Facebook allein die Verantwortung. Unsere gemeinsamen Verpflichtungen wurden auch in einer öffentlich zugänglichen Vereinbarung unter <a className="adsimple-312817351" href="https://www.facebook.com/legal/controller_addendum">https://www.facebook.com/legal/controller_addendum</a> verankert. Darin ist etwa festgehalten, dass wir Sie klar über den Einsatz der Facebook-Tools auf unserer Seite informieren müssen. Weiters sind wir auch dafür verantwortlich, dass die Tools datenschutzrechtlich sicher in unsere Website eingebunden sind. Facebook ist hingegen beispielsweise für die Datensicherheit der Facebook-Produkte verantwortlich. Bei etwaigen Fragen zur Datenerhebung und Datenverarbeitung durch Facebook können Sie sich direkt an das Unternehmen wenden. Wenn Sie die Frage an uns richten, sind wir dazu verpflichtet diese an Facebook weiterleiten.</p>
				<p>Im Folgenden geben wir einen Überblick über die verschiedenen Facebook Tools, welche Daten an Facebook gesendet werden und wie Sie diese Daten löschen können.</p>
				<p>Neben vielen anderen Produkten bietet Facebook auch die sogenannten &#8220;Facebook Business Tools&#8221; an. Das ist die offizielle Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:</p>
				<ul className="adsimple-312817351">
				<li className="adsimple-312817351">Facebook-Pixel</li>
				<li className="adsimple-312817351">soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)</li>
				<li className="adsimple-312817351">Facebook Login</li>
				<li className="adsimple-312817351">Account Kit</li>
				<li className="adsimple-312817351">APIs (Programmierschnittstelle)</li>
				<li className="adsimple-312817351">SDKs (Sammlung von Programmierwerkzeugen)</li>
				<li className="adsimple-312817351">Plattform-Integrationen</li>
				<li className="adsimple-312817351">Plugins</li>
				<li className="adsimple-312817351">Codes</li>
				<li className="adsimple-312817351">Spezifikationen</li>
				<li className="adsimple-312817351">Dokumentationen</li>
				<li className="adsimple-312817351">Technologien und Dienstleistungen</li>
				</ul>
				<p>Durch diese Tools erweitert Facebook Dienstleistungen und hat die Möglichkeit, Informationen über User-Aktivitäten außerhalb von Facebook zu erhalten.</p>
				<h3 className="adsimple-312817351">Warum verwenden wir Facebook-Tools auf unserer Website?</h3>
				<p>Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den Usern passende Werbung gezeigt werden kann, benötigt Facebook allerdings Informationen über die Wünsche und Bedürfnisse der Menschen. So werden dem Unternehmen Informationen über das Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook bessere User-Daten und kann interessierten Menschen die passende Werbung über unsere Produkte bzw. Dienstleistungen anzeigen. Die Tools ermöglichen somit maßgeschneiderte Werbekampagnen auf Facebook.</p>
				<p>Daten über Ihr Verhalten auf unserer Webseite nennt Facebook „Event-Daten“. Diese werden auch für Messungs- und Analysedienste verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“ über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren Einblick, wie Sie unsere Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte auf unserer Seite direkt auf Facebook teilen.</p>
				<h3 className="adsimple-312817351">Welche Daten werden von Facebook-Tools gespeichert?</h3>
				<p>Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse versandt werden.</p>
				<p>Facebook verwendet diese Informationen, um die Daten mit den Daten, die es selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden, erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig großer Datensatz in eine Zeichenkette transformiert wird. Dies dient auch der Verschlüsselung von Daten.</p>
				<p>Neben den Kontaktdaten werden auch „Event-Daten“ übermittelt. Unter „Event-Daten“ sind jene Informationen gemeint, die wir über Sie auf unserer Webseite erhalten. Zum Beispiel, welche Unterseiten Sie besuchen oder welche Produkte Sie bei uns kaufen. Facebook teilt die erhaltenen Informationen nicht mit Drittanbietern (wie beispielsweise Werbetreibende), außer das Unternehmen hat eine explizite Genehmigung oder ist rechtlich dazu verpflichtet. „Event-Daten“ können auch mit Kontaktdaten verbunden werden. Dadurch kann Facebook bessere personalisierte Werbung anbieten. Nach dem bereits erwähnten Abgleichungsprozess löscht Facebook die Kontaktdaten wieder.</p>
				<p>Um Werbeanzeigen optimiert ausliefern zu können, verwendet Facebook die Event-Daten nur, wenn diese mit anderen Daten (die auf andere Weise von Facebook erfasst wurden) zusammengefasst wurden. Diese Event-Daten nützt Facebook auch für Sicherheits-, Schutz-, Entwicklungs- und Forschungszwecke. Viele dieser Daten werden über Cookies zu Facebook übertragen. Cookies sind kleine Text-Dateien, die zum Speichern von Daten bzw. Informationen in Browsern verwendet werden. Je nach verwendeten Tools und abhängig davon, ob Sie Facebook-Mitglied sind, werden unterschiedlich viele Cookies in Ihrem Browser angelegt. In den Beschreibungen der einzelnen Facebook Tools gehen wir näher auf einzelne Facebook-Cookies ein. Allgemeine Informationen über die Verwendung von Facebook-Cookies erfahren Sie auch auf <a className="adsimple-312817351" href="https://www.facebook.com/policies/cookies?tid=312817351">https://www.facebook.com/policies/cookies</a>.</p>
				<h3 className="adsimple-312817351">Wie lange und wo werden die Daten gespeichert?</h3>
				<p>Grundsätzlich speichert Facebook Daten bis sie nicht mehr für die eigenen Dienste und Facebook-Produkte benötigt werden. Facebook hat auf der ganzen Welt Server verteilt, wo seine Daten gespeichert werden. Kundendaten werden allerdings, nachdem sie mit den eigenen Userdaten abgeglichen wurden, innerhalb von 48 Stunden gelöscht.</p>
				<h3 className="adsimple-312817351">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
				<p>Entsprechend der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Berichtigung, Übertragbarkeit und Löschung Ihrer Daten.</p>
				<p>Eine komplette Löschung der Daten erfolgt nur, wenn Sie Ihr Facebook-Konto vollständig löschen. Und so funktioniert das Löschen Ihres Facebook-Kontos:</p>
				<p>1) Klicken Sie rechts bei Facebook auf Einstellungen.</p>
				<p>2) Anschließend klicken Sie in der linken Spalte auf „Deine Facebook-Informationen“.</p>
				<p>3) Nun klicken Sie &#8220;Deaktivierung und Löschung&#8221;.</p>
				<p>4) Wählen Sie jetzt „Konto löschen“ und klicken Sie dann auf „Weiter und Konto löschen“</p>
				<p>5) Geben Sie nun Ihr Passwort ein, klicken Sie auf „Weiter“ und dann auf „Konto löschen“</p>
				<p>Die Speicherung der Daten, die Facebook über unsere Seite erhält, erfolgt unter anderem über Cookies (z.B. bei sozialen Plugins). In Ihrem Browser können Sie einzelne oder alle Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies auf unterschiedliche Art und Weise. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
				<p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Facebook-Tools verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong className="adsimple-312817351">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong className="adsimple-312817351">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien von Facebook anzusehen.</p>
				<p>Facebook verarbeitet Daten von Ihnen u.a. auch in den USA. Facebook bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie auf <a className="adsimple-312817351" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="follow noopener">https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
				<p>Zudem verwendet Facebook sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Facebook, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="adsimple-312817351" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
				</p>
				<p>Die Facebook Datenverarbeitungsbedingung, welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a className="adsimple-312817351" href="https://www.facebook.com/legal/terms/dataprocessing">https://www.facebook.com/legal/terms/dataprocessing</a>.</p>
				<p>Wir hoffen, wir haben Ihnen die wichtigsten Informationen über die Nutzung und Datenverarbeitung durch die Facebook-Tools nähergebracht. Wenn Sie mehr darüber erfahren wollen, wie Facebook Ihre Daten verwendet, empfehlen wir Ihnen die Datenrichtlinien auf <a className="adsimple-312817351" href="https://www.facebook.com/privacy/policy/?tid=312817351">https://www.facebook.com/privacy/policy/</a>.</p>
				<h2 id="instagram-datenschutzerklaerung" className="adsimple-312817351">Instagram Datenschutzerklärung</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Instagram Datenschutzerklärung Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Besucher der Website<br />
				&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
				&#x1f4d3; Verarbeitete Daten: Daten wie etwa Daten zum Nutzerverhalten, Informationen zu Ihrem Gerät und Ihre IP-Adresse.<br />
				Mehr Details dazu finden Sie weiter unten in der Datenschutzerklärung.<br />
				&#x1f4c5; Speicherdauer: bis Instagram die Daten für ihre Zwecke nicht mehr benötigt<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was ist Instagram?</h3>
				<p>Wir haben auf unserer Webseite Funktionen von Instagram eingebaut. Instagram ist eine Social Media Plattform des Unternehmens Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA. Instagram ist seit 2012 ein Tochterunternehmen von Meta Platforms Inc. und gehört zu den Facebook-Produkten. Das Einbetten von Instagram-Inhalten auf unserer Webseite nennt man Embedding. Dadurch können wir Ihnen Inhalte wie Buttons, Fotos oder Videos von Instagram direkt auf unserer Webseite zeigen. Wenn Sie Webseiten unserer Webpräsenz aufrufen, die eine Instagram-Funktion integriert haben, werden Daten an Instagram übermittelt, gespeichert und verarbeitet. Instagram verwendet dieselben Systeme und Technologien wie Facebook. Ihre Daten werden somit über alle Facebook-Firmen hinweg verarbeitet.</p>
				<p>Im Folgenden wollen wir Ihnen einen genaueren Einblick geben, warum Instagram Daten sammelt, um welche Daten es sich handelt und wie Sie die Datenverarbeitung weitgehend kontrollieren können. Da Instagram zu Meta Platforms Inc. gehört, beziehen wir unsere Informationen einerseits von den Instagram-Richtlinien, andererseits allerdings auch von den Meta-Datenschutzrichtlinien selbst.</p>
				<p>Instagram ist eines der bekanntesten Social Media Netzwerken weltweit. Instagram kombiniert die Vorteile eines Blogs mit den Vorteilen von audiovisuellen Plattformen wie YouTube oder Vimeo. Sie können auf „Insta“ (wie viele der User die Plattform salopp nennen) Fotos und kurze Videos hochladen, mit verschiedenen Filtern bearbeiten und auch in anderen sozialen Netzwerken verbreiten. Und wenn Sie selbst nicht aktiv sein wollen, können Sie auch nur anderen interessante Users folgen.</p>
				<h3 className="adsimple-312817351">Warum verwenden wir Instagram auf unserer Website?</h3>
				<p>Instagram ist jene Social Media Plattform, die in den letzten Jahren so richtig durch die Decke ging. Und natürlich haben auch wir auf diesen Boom reagiert. Wir wollen, dass Sie sich auf unserer Webseite so wohl wie möglich fühlen. Darum ist für uns eine abwechslungsreiche Aufbereitung unserer Inhalte selbstverständlich. Durch die eingebetteten Instagram-Funktionen können wir unseren Content mit hilfreichen, lustigen oder spannenden Inhalten aus der Instagram-Welt bereichern. Da Instagram eine Tochtergesellschaft von Facebook ist, können uns die erhobenen Daten auch für personalisierte Werbung auf Facebook dienlich sein. So bekommen unsere Werbeanzeigen nur Menschen, die sich wirklich für unsere Produkte oder Dienstleistungen interessieren.</p>
				<p>Instagram nützt die gesammelten Daten auch zu Messungs- und Analysezwecken. Wir bekommen zusammengefasste Statistiken und so mehr Einblick über Ihre Wünsche und Interessen. Wichtig ist zu erwähnen, dass diese Berichte Sie nicht persönlich identifizieren.</p>
				<h3 className="adsimple-312817351">Welche Daten werden von Instagram gespeichert?</h3>
				<p>Wenn Sie auf eine unserer Seiten stoßen, die Instagram-Funktionen (wie Instagrambilder oder Plug-ins) eingebaut haben, setzt sich Ihr Browser automatisch mit den Servern von Instagram in Verbindung. Dabei werden Daten an Instagram versandt, gespeichert und verarbeitet. Und zwar unabhängig, ob Sie ein Instagram-Konto haben oder nicht. Dazu zählen Informationen über unserer Webseite, über Ihren Computer, über getätigte Käufe, über Werbeanzeigen, die Sie sehen und wie Sie unser Angebot nutzen. Weiters werden auch Datum und Uhrzeit Ihrer Interaktion mit Instagram gespeichert. Wenn Sie ein Instagram-Konto haben bzw. eingeloggt sind, speichert Instagram deutlich mehr Daten über Sie.</p>
				<p>Facebook unterscheidet zwischen Kundendaten und Eventdaten. Wir gehen davon aus, dass dies bei Instagram genau so der Fall ist. Kundendaten sind zum Beispiel Name, Adresse, Telefonnummer und IP-Adresse. Diese Kundendaten werden erst an Instagram übermittelt werden, wenn Sie zuvor „gehasht“ wurden. Hashing meint, ein Datensatz wird in eine Zeichenkette verwandelt. Dadurch kann man die Kontaktdaten verschlüsseln. Zudem werden auch die oben genannten „Event-Daten“ übermittelt. Unter „Event-Daten“ versteht Facebook – und folglich auch Instagram – Daten über Ihr Userverhalten. Es kann auch vorkommen, dass Kontaktdaten mit Event-Daten kombiniert werden. Die erhobenen Kontaktdaten werden mit den Daten, die Instagram bereits von Ihnen hat, abgeglichen.</p>
				<p>Über kleine Text-Dateien (Cookies), die meist in Ihrem Browser gesetzt werden, werden die gesammelten Daten an Facebook übermittelt. Je nach verwendeten Instagram-Funktionen und ob Sie selbst ein Instagram-Konto haben, werden unterschiedlich viele Daten gespeichert.</p>
				<p>Wir gehen davon aus, dass bei Instagram die Datenverarbeitung gleich funktioniert wie bei Facebook. Das bedeutet: wenn Sie ein Instagram-Konto haben oder <a className="adsimple-312817351" href="https://www.instagram.com/?tid=312817351" target="_blank" rel="noopener noreferrer">www.instagram.com</a> besucht haben, hat Instagram zumindest ein Cookie gesetzt. Wenn das der Fall ist, sendet Ihr Browser über das Cookie Infos an Instagram, sobald Sie mit einer Instagram-Funktion in Berührung kommen. Spätestens nach 90 Tagen (nach Abgleichung) werden diese Daten wieder gelöscht bzw. anonymisiert. Obwohl wir uns intensiv mit der Datenverarbeitung von Instagram beschäftigt haben, können wir nicht ganz genau sagen, welche Daten Instagram exakt sammelt und speichert.</p>
				<p>Im Folgenden zeigen wir Ihnen Cookies, die in Ihrem Browser mindestens gesetzt werden, wenn Sie auf eine Instagram-Funktion (wie z.B. Button oder ein Insta-Bild) klicken. Bei unserem Test gehen wir davon aus, dass Sie kein Instagram-Konto haben. Wenn Sie bei Instagram eingeloggt sind, werden natürlich deutlich mehr Cookies in Ihrem Browser gesetzt.</p>
				<p>Diese Cookies wurden bei unserem Test verwendet:</p>
				<p>
				<strong className="adsimple-312817351">Name: </strong>csrftoken<br />
				<strong className="adsimple-312817351">Wert: </strong>&#8220;&#8221;<br />
				<strong className="adsimple-312817351">Verwendungszweck: </strong>Dieses Cookie wird mit hoher Wahrscheinlichkeit aus Sicherheitsgründen gesetzt, um Fälschungen von Anfragen zu verhindern. Genauer konnten wir das allerdings nicht in Erfahrung bringen.<br />
				<strong className="adsimple-312817351">Ablaufdatum:</strong> nach einem Jahr</p>
				<p>
				<strong className="adsimple-312817351">Name: </strong>mid<br />
				<strong className="adsimple-312817351">Wert: </strong>&#8220;&#8221;<br />
				<strong className="adsimple-312817351">Verwendungszweck: </strong>Instagram setzt dieses Cookie, um die eigenen Dienstleistungen und Angebote in und außerhalb von Instagram zu optimieren. Das Cookie legt eine eindeutige User-ID fest.<br />
				<strong className="adsimple-312817351">Ablaufdatum:</strong> nach Ende der Sitzung</p>
				<p>
				<strong className="adsimple-312817351">Name:</strong> fbsr_312817351124024<br />
				<strong className="adsimple-312817351">Wert: </strong>keine Angaben<br />
				<strong className="adsimple-312817351">Verwendungszweck: </strong>Dieses Cookie speichert die Log-in-Anfrage für User der Instagram-App.<strong className="adsimple-312817351">
				<br />
				</strong>
				<strong className="adsimple-312817351">Ablaufdatum:</strong> nach Ende der Sitzung</p>
				<p>
				<strong className="adsimple-312817351">Name:</strong> rur<br />
				<strong className="adsimple-312817351">Wert: </strong>ATN<br />
				<strong className="adsimple-312817351">Verwendungszweck: </strong>Dabei handelt es sich um ein Instagram-Cookie, das die Funktionalität auf Instagram gewährleistet.<br />
				<strong className="adsimple-312817351">Ablaufdatum:</strong> nach Ende der Sitzung</p>
				<p>
				<strong className="adsimple-312817351">Name:</strong> urlgen<br />
				<strong className="adsimple-312817351">Wert: </strong>&#8220;&lbrace;&#8221;194.96.75.33&#8221;: 1901&rbrace;:1iEtYv:Y833k2_UjKvXgYe312817351&#8221;<br />
				<strong className="adsimple-312817351">Verwendungszweck: </strong>Dieses Cookie dient den Marketingzwecken von Instagram.<br />
				<strong className="adsimple-312817351">Ablaufdatum:</strong> nach Ende der Sitzung</p>
				<p>
				<strong className="adsimple-312817351">Anmerkung:</strong> Wir können hier keinen Vollständigkeitsanspruch erheben. Welche Cookies im individuellen Fall gesetzt werden, hängt von den eingebetteten Funktionen und Ihrer Verwendung von Instagram ab.</p>
				<h3 className="adsimple-312817351">Wie lange und wo werden die Daten gespeichert?</h3>
				<p>Instagram teilt die erhaltenen Informationen zwischen den Facebook-Unternehmen mit externen Partnern und mit Personen, mit denen Sie sich weltweit verbinden. Die Datenverarbeitung erfolgt unter Einhaltung der eigenen Datenrichtlinie. Ihre Daten sind, unter anderem aus Sicherheitsgründen, auf den Facebook-Servern auf der ganzen Welt verteilt. Die meisten dieser Server stehen in den USA.</p>
				<h3 className="adsimple-312817351">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
				<p>Dank der Datenschutz Grundverordnung haben Sie das Recht auf Auskunft, Übertragbarkeit, Berichtigung und Löschung Ihrer Daten. In den Instagram-Einstellungen können Sie Ihre Daten verwalten. Wenn Sie Ihre Daten auf Instagram völlig löschen wollen, müssen Sie Ihr Instagram-Konto dauerhaft löschen.</p>
				<p>Und so funktioniert die Löschung des Instagram-Kontos:</p>
				<p>Öffnen Sie zuerst die Instagram-App. Auf Ihrer Profilseite gehen Sie nach unten und klicken Sie auf „Hilfebereich“. Jetzt kommen Sie auf die Webseite des Unternehmens. Klicken Sie auf der Webseite auf „Verwalten des Kontos“ und dann auf „Dein Konto löschen“.</p>
				<p>Wenn Sie Ihr Konto ganz löschen, löscht Instagram Posts wie beispielsweise Ihre Fotos und Status-Updates. Informationen, die andere Personen über Sie geteilt haben, gehören nicht zu Ihrem Konto und werden folglich nicht gelöscht.</p>
				<p>Wie bereits oben erwähnt, speichert Instagram Ihre Daten in erster Linie über Cookies. Diese Cookies können Sie in Ihrem Browser verwalten, deaktivieren oder löschen. Abhängig von Ihrem Browser funktioniert die Verwaltung immer ein bisschen anders. Unter dem Abschnitt „Cookies“ finden Sie die entsprechenden Links zu den jeweiligen Anleitungen der bekanntesten Browser.</p>
				<p>Sie können auch grundsätzlich Ihren Browser so einrichten, dass Sie immer informiert werden, wenn ein Cookie gesetzt werden soll. Dann können Sie immer individuell entscheiden, ob Sie das Cookie zulassen wollen oder nicht.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Wenn Sie eingewilligt haben, dass Daten von Ihnen durch eingebundene Social-Media-Elemente verarbeitet und gespeichert werden können, gilt diese Einwilligung als Rechtsgrundlage der Datenverarbeitung <strong className="adsimple-312817351">(Art. 6 Abs. 1 lit. a DSGVO)</strong>. Grundsätzlich werden Ihre Daten auch auf Grundlage unseres berechtigten Interesses <strong className="adsimple-312817351">(Art. 6 Abs. 1 lit. f DSGVO)</strong> an einer schnellen und guten Kommunikation mit Ihnen oder anderen Kunden und Geschäftspartnern gespeichert und verarbeitet. Wir setzen die eingebundene Social-Media-Elemente gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Die meisten Social-Media-Plattformen setzen auch Cookies in Ihrem Browser, um Daten zu speichern. Darum empfehlen wir Ihnen, unseren Datenschutztext über Cookies genau durchzulesen und die Datenschutzerklärung oder die Cookie-Richtlinien des jeweiligen Dienstanbieters anzusehen.</p>
				<p>Instagram verarbeitet Daten von Ihnen u.a. auch in den USA. Instagram bzw. Meta Platforms ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie auf <a className="adsimple-312817351" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="follow noopener"> https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
				<p>Zudem verwendet Instagram sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Instagram, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="adsimple-312817351" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
				</p>
				<p>Wir haben versucht, Ihnen die wichtigsten Informationen über die Datenverarbeitung durch Instagram näherzubringen. Auf <a className="adsimple-312817351" href="https://privacycenter.instagram.com/policy/" target="_blank" rel="follow noopener">https://privacycenter.instagram.com/policy/</a> können Sie sich noch näher mit den Datenrichtlinien von Instagram auseinandersetzen.</p>
				<h2 id="webdesign-einleitung" className="adsimple-312817351">Webdesign Einleitung</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Webdesign Datenschutzerklärung Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Besucher der Website<br />
				&#x1f91d; Zweck: Verbesserung der Nutzererfahrung<br />
				&#x1f4d3; Verarbeitete Daten: Welche Daten verarbeitet werden, hängt stark von den verwendeten Diensten ab. Meist handelt es sich etwa um IP-Adresse, technische Daten, Spracheinstellungen,  Browserversion, Bildschirmauflösung und Name des Browsers. Mehr Details dazu finden Sie bei den jeweils eingesetzten Webdesign-Tools.<br />
				&#x1f4c5; Speicherdauer: abhängig von den eingesetzten Tools<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was ist Webdesign?</h3>
				<p>Wir verwenden auf unserer Website verschiedene Tools, die unserem Webdesign dienen. Bei Webdesign geht es nicht, wie oft angenommen, nur darum, dass unsere Website hübsch aussieht, sondern auch um Funktionalität und Leistung. Aber natürlich ist die passende Optik einer Website auch eines der großen Ziele professionellen Webdesigns. Webdesign ist ein Teilbereich des Mediendesigns und beschäftigt sich sowohl mit der visuellen als auch der strukturellen und funktionalen Gestaltung einer Website. Ziel ist es mit Hilfe von Webdesign Ihre Erfahrung auf unserer Website zu verbessern. Im Webdesign-Jargon spricht man in diesem Zusammenhang von User-Experience (UX) und Usability. Unter User Experience versteht man alle Eindrücke und Erlebnisse, die der Websitebesucher auf einer Website erfährt. Ein Unterpunkt der User Experience ist die Usability. Dabei geht es um die Nutzerfreundlichkeit einer Website. Wert gelegt wird hier vor allem darauf, dass Inhalte, Unterseiten oder Produkte klar strukturiert sind und Sie leicht und schnell finden, wonach Sie suchen. Um Ihnen die bestmögliche Erfahrung auf unserer Website zu bieten, verwenden wir auch sogenannte Webdesign-Tools von Drittanbietern. Unter die Kategorie „Webdesign“ fallen in dieser Datenschutzerklärung also alle Dienste, die unsere Website gestalterisch verbessern. Das können beispielsweise Schriftarten, diverse Plugins oder andere eingebundene Webdesign-Funktionen sein.</p>
				<h3 className="adsimple-312817351">Warum verwenden wir Webdesign-Tools?</h3>
				<p>Wie Sie Informationen auf einer Website aufnehmen, hängt sehr stark von der Struktur, der Funktionalität und der visuellen Wahrnehmung der Website ab. Daher wurde auch für uns ein gutes und professionelles Webdesign immer wichtiger. Wir arbeiten ständig an der Verbesserung unserer Website und sehen dies auch als erweiterte Dienstleistung für Sie als Websitebesucher. Weiters hat eine schöne und funktionierende Website auch wirtschaftliche Vorteile für uns. Schließlich werden Sie uns nur besuchen und unsere Angebote in Anspruch nehmen, wenn Sie sich rundum wohl fühlen.</p>
				<h3 className="adsimple-312817351">Welche Daten werden durch Webdesign-Tools gespeichert?</h3>
				<p>Wenn Sie unsere Website besuchen, können Webdesign-Elemente in unseren Seiten eingebunden sein, die auch Daten verarbeiten können. Um welche Daten es sich genau handelt, hängt natürlich stark von den verwendeten Tools ab. Weiter unter sehen Sie genau, welche Tools wir für unsere Website verwenden. Wir empfehlen Ihnen für nähere Informationen über die Datenverarbeitung auch die jeweilige Datenschutzerklärung der verwendeten Tools durchzulesen. Meistens erfahren Sie dort, welche Daten verarbeitet werden, ob Cookies eingesetzt werden und wie lange die Daten aufbewahrt werden. Durch Schriftarten wie etwa Google Fonts werden beispielsweise auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen.</p>
				<h3 className="adsimple-312817351">Dauer der Datenverarbeitung</h3>
				<p>Wie lange Daten verarbeitet werden, ist sehr individuell und hängt von den eingesetzten Webdesign-Elementen ab. Wenn Cookies beispielsweise zum Einsatz kommen, kann die Aufbewahrungsdauer nur eine Minute, aber auch ein paar Jahre dauern. Machen Sie sich diesbezüglich bitte schlau. Dazu empfehlen wir Ihnen einerseits unseren allgemeinen Textabschnitt über Cookies sowie die Datenschutzerklärungen der eingesetzten Tools. Dort erfahren Sie in der Regel, welche Cookies genau eingesetzt werden, und welche Informationen darin gespeichert werden. Google-Font-Dateien werden zum Beispiel ein Jahr gespeichert. Damit soll die Ladezeit einer Website verbessert werden. Grundsätzlich werden Daten immer nur so lange aufbewahrt, wie es für die Bereitstellung des Dienstes nötig ist. Bei gesetzlichen Vorschreibungen können Daten auch länger gespeichert werden.</p>
				<h3 className="adsimple-312817351">Widerspruchsrecht</h3>
				<p>Sie haben auch jederzeit das Recht und die Möglichkeit Ihre Einwilligung zur Verwendung von Cookies bzw. Drittanbietern zu widerrufen. Das funktioniert entweder über unser Cookie-Management-Tool oder über andere Opt-Out-Funktionen. Sie können auch die Datenerfassung durch Cookies verhindern, indem Sie in Ihrem Browser die Cookies verwalten, deaktivieren oder löschen. Unter Webdesign-Elementen (meistens bei Schriftarten) gibt es allerdings auch Daten, die nicht ganz so einfach gelöscht werden können. Das ist dann der Fall, wenn Daten direkt bei einem Seitenaufruf automatisch erhoben und an einen Drittanbieter (wie z. B. Google) übermittelt werden. Wenden Sie sich dann bitte an den Support des entsprechenden Anbieters. Im Fall von Google erreichen Sie den Support unter <a className="adsimple-312817351" href="https://support.google.com/?hl=de">https://support.google.com/?hl=de</a>.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Wenn Sie eingewilligt haben, dass Webdesign-Tools eingesetzt werden dürfen, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut Art. 6 Abs. 1 lit. a DSGVO (Einwilligung) die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Webdesign-Tools vorkommen kann, dar. Von unserer Seite besteht zudem ein berechtigtes Interesse, das Webdesign auf unserer Website zu verbessern. Schließlich können wir Ihnen nur dann ein schönes und professionelles Webangebot liefern. Die dafür entsprechende Rechtsgrundlage ist Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen). Wir setzen Webdesign-Tools gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben. Das wollen wir hier auf jeden Fall nochmals betonen.</p>
				<p>Informationen zu speziellen Webdesign-Tools erhalten Sie &#8211; sofern vorhanden &#8211; in den folgenden Abschnitten.</p>
				<h2 id="google-fonts-datenschutzerklaerung" className="adsimple-312817351">Google Fonts Datenschutzerklärung</h2>
				<table border="1" cellPadding="15">
				<tbody>
				<tr>
				<td>
				<strong className="adsimple-312817351">Google Fonts Datenschutzerklärung Zusammenfassung</strong>
				<br />
				&#x1f465; Betroffene: Besucher der Website<br />
				&#x1f91d; Zweck: Optimierung unserer Serviceleistung<br />
				&#x1f4d3; Verarbeitete Daten: Daten wie etwa IP-Adresse und CSS- und Schrift-Anfragen<br />
				Mehr Details dazu finden Sie weiter unten in dieser Datenschutzerklärung.<br />
				&#x1f4c5; Speicherdauer: Font-Dateien werden bei Google ein Jahr gespeichert<br />
				&#x2696;&#xfe0f; Rechtsgrundlagen: Art. 6 Abs. 1 lit. a DSGVO (Einwilligung), Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</td>
				</tr>
				</tbody>
				</table>
				<h3 className="adsimple-312817351">Was sind Google Fonts?</h3>
				<p>Auf unserer Website verwenden wir Google Fonts. Das sind die &#8220;Google-Schriften&#8221; der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
				<p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</p>
				<p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a className="adsimple-312817351" href="https://de.wikipedia.org/wiki/Google_LLC?tid=312817351">Google</a> Ihren Nutzern kostenlos zu Verfügung stellen.</p>
				<p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
				<h3 className="adsimple-312817351">Warum verwenden wir Google Fonts auf unserer Website?</h3>
				<p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.</p>
				<h3 className="adsimple-312817351">Welche Daten werden von Google gespeichert?</h3>
				<p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.</p>
				<p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
				<p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
				<h3 className="adsimple-312817351">Wie lange und wo werden die Daten gespeichert?</h3>
				<p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.</p>
				<p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p>
				<h3 className="adsimple-312817351">Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
				<p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf <a className="adsimple-312817351" href="https://support.google.com/?hl=de&amp;tid=312817351">https://support.google.com/?hl=de&amp;tid=312817351</a> kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
				<p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a className="adsimple-312817351" href="https://developers.google.com/fonts/faq?tid=312817351">https://developers.google.com/fonts/faq?tid=312817351</a>. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.</p>
				<h3 className="adsimple-312817351">Rechtsgrundlage</h3>
				<p>Wenn Sie eingewilligt haben, dass Google Fonts eingesetzt werden darf, ist die Rechtsgrundlage der entsprechenden Datenverarbeitung diese Einwilligung. Diese Einwilligung stellt laut<strong className="adsimple-312817351"> Art. 6 Abs. 1 lit. a DSGVO (Einwilligung)</strong> die Rechtsgrundlage für die Verarbeitung personenbezogener Daten, wie sie bei der Erfassung durch Google Fonts vorkommen kann, dar.</p>
				<p>Von unserer Seite besteht zudem ein berechtigtes Interesse, Google Font zu verwenden, um unser Online-Service zu optimieren. Die dafür entsprechende Rechtsgrundlage ist <strong className="adsimple-312817351">Art. 6 Abs. 1 lit. f DSGVO (Berechtigte Interessen)</strong>. Wir setzen Google Font gleichwohl nur ein, soweit Sie eine Einwilligung erteilt haben.</p>
				<p>Google verarbeitet Daten von Ihnen u.a. auch in den USA. Google ist aktiver Teilnehmer des EU-US Data Privacy Frameworks, wodurch der korrekte und sichere Datentransfer personenbezogener Daten von EU-Bürgern in die USA geregelt wird. Mehr Informationen dazu finden Sie auf <a className="adsimple-312817351" href="https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en" target="_blank" rel="follow noopener"> https://commission.europa.eu/document/fa09cbad-dd7d-4684-ae60-be03fcb0fddf_en</a>.</p>
				<p>Zudem verwendet Google sogenannte Standardvertragsklauseln (= Art. 46. Abs. 2 und 3 DSGVO). Standardvertragsklauseln (Standard Contractual Clauses – SCC) sind von der EU-Kommission bereitgestellte Mustervorlagen und sollen sicherstellen, dass Ihre Daten auch dann den europäischen Datenschutzstandards entsprechen, wenn diese in Drittländer (wie beispielsweise in die USA) überliefert und dort gespeichert werden. Durch das EU-US Data Privacy Framework und durch die Standardvertragsklauseln verpflichtet sich Google, bei der Verarbeitung Ihrer relevanten Daten, das europäische Datenschutzniveau einzuhalten, selbst wenn die Daten in den USA gespeichert, verarbeitet und verwaltet werden. Diese Klauseln basieren auf einem Durchführungsbeschluss der EU-Kommission. Sie finden den Beschluss und die entsprechenden Standardvertragsklauseln u.a. hier: <a className="adsimple-312817351" href="https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de" target="_blank" rel="follow noopener">https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj?locale=de</a>
				</p>
				<p>Die Google Ads Datenverarbeitungsbedingungen (Google Ads Data Processing Terms), welche auf die Standardvertragsklauseln verweisen, finden Sie unter <a className="adsimple-312817351" href="https://business.safety.google/intl/de/adsprocessorterms/" target="_blank" rel="follow noopener">https://business.safety.google/intl/de/adsprocessorterms/</a>.</p>
				<p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf <a className="adsimple-312817351" href="https://policies.google.com/privacy?hl=de&amp;tid=312817351">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.</p>
				<h2 id="schlusswort" className="adsimple-312817351">Schlusswort</h2>
				<p>Herzlichen Glückwunsch! Wenn Sie diese Zeilen lesen, haben Sie sich wirklich durch unsere gesamte Datenschutzerklärung „gekämpft“ oder zumindest bis hier hin gescrollt. Wie Sie am Umfang unserer Datenschutzerklärung sehen, nehmen wir den Schutz Ihrer persönlichen Daten, alles andere als auf die leichte Schulter.<br />
				Uns ist es wichtig, Sie nach bestem Wissen und Gewissen über die Verarbeitung personenbezogener Daten zu informieren. Dabei wollen wir Ihnen aber nicht nur mitteilen, welche Daten verarbeitet werden, sondern auch die Beweggründe für die Verwendung diverser Softwareprogramme näherbringen. In der Regel klingen Datenschutzerklärung sehr technisch und juristisch. Da die meisten von Ihnen aber keine Webentwickler oder Juristen sind, wollten wir auch sprachlich einen anderen Weg gehen und den Sachverhalt in einfacher und klarer Sprache erklären. Immer ist dies natürlich aufgrund der Thematik nicht möglich. Daher werden die wichtigsten Begriffe am Ende der Datenschutzerklärung näher erläutert.<br />
				Bei Fragen zum Thema Datenschutz auf unserer Website zögern Sie bitte nicht, uns oder die verantwortliche Stelle zu kontaktieren. Wir wünschen Ihnen noch eine schöne Zeit und hoffen, Sie auf unserer Website bald wieder begrüßen zu dürfen.</p>
				<p>Alle Texte sind urheberrechtlich geschützt.</p>
				<p style={{"marginTop": "15px"}}>Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator/" title="Datenschutz Generator Deutschland von AdSimple">Datenschutz Generator Deutschland</a> von AdSimple</p>

			</div>
		</div>
		</>
	);
};

export default DataProtectionPage;