import { useNavigate } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';

import logo from '../resources/Logo-512.rechteck.rainbow.png';

import '../styles/main-navbar.css';

function MainNavbarBootstrap() {
	const navigate = useNavigate();
	const LinkNavigation = (eventKey, event) => {
		event.preventDefault();
		navigate(eventKey);
	};

	return (
		<div className='main-navbar'>
		<Navbar expand="md">
			<Container className='main-navbar-box'>
				<Navbar.Brand href="/">
					<img
						src={logo}
						height="60px"
						alt="Home"
					/>
				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav
						className="me-auto"
						onSelect={LinkNavigation}
					>
						<Nav.Link className="main-navbar-color-red" eventKey="/kita" >Unsere KiTa</Nav.Link>
						<Nav.Link className="main-navbar-color-orange" eventKey="/concept">Konzeption</Nav.Link>
						<Nav.Link className="main-navbar-color-green" eventKey="/news">Aktuelles</Nav.Link>
						<Nav.Link className="main-navbar-color-blue" eventKey="/registration">Anmeldung</Nav.Link>
						<NavDropdown className="main-navbar-color-lila" title="Kontakt" id="basic-nav-dropdown">
							<NavDropdown.Item className="main-navbar-color-lila" eventKey="/contact">Kontakt</NavDropdown.Item>
							<NavDropdown.Item className="main-navbar-color-lila" eventKey="/impressum">Impressum</NavDropdown.Item>
							<NavDropdown.Item className="main-navbar-color-lila" eventKey="/datenschutzerklaerung">Datenschutz</NavDropdown.Item>
							{/* <NavDropdown.Divider /> */}
						</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
		</div>
	);
}

export default MainNavbarBootstrap;