import { useNavigate } from 'react-router-dom';

import { Button, Icon } from '@ui5/webcomponents-react';
import config from '../../config';
import TitleBanner from '../sectionComponents/title-banner';

import "@ui5/webcomponents-icons/dist/arrow-right.js";
import '../../styles/pages/common-page.css';
import '../../styles/pages/registration-page.css';


const RegistrationPage = () => {
	const navigate = useNavigate();
	const onButtonClick = (event) => {
		event.preventDefault();
		navigate("/contact");
	};

	return (
		<>
		<TitleBanner title="Anmeldung" />
		<div className="common-page">
			<div className="common-page-box">
				<h1>Anmeldung Ihres Kindes / Ihrer Kinder</h1>
				<p>
					Nutzen Sie hierfür bitte das zentrale Anmeldesystem der Stadt Heidelberg:<br />
				</p>
				<a 
					className="common-link-lila common-text-header-2"
					target="_blank"
					rel="noreferrer"
					href="https://www.kitaweb-bw.de/kita/vormStdPage.jsp?gkz=221000">
					https://www.kitaweb-bw.de
				</a>
				{/* <p>
					Wir sind noch nicht in das zentrale Anmeldesystem der Stadt Heidelberg integriert.
					Wir würden uns jedoch freuen wenn Sie sich direkt bei uns melden, um ihr Kind schon mal vorzumerken.
					Sobald das zentrale Anmeldesystem für uns freigeschaltet ist, werden wir sie sehr gerne darüber informieren.
				</p> */}
			</div>
			<div className="common-page-box">
				<h1>Kindergarten Beiträge 2024/2025</h1>
				<div className="registration-page-grid-box">
					<div className="registration-page-main-row">
					<span className="registration-text-green common-text-bold">2024</span>
					</div>
					<div className="registration-page-header">
						Betreuungs<br />Beitrag
					</div>
					<div className="registration-page-header">
						Vergünstigungen Stadt Heidelberg
					</div>
					<div className="registration-page-main-row">
						<span className="registration-text-orange common-text-bold">VÖ</span> <br />7 - 14 Uhr
					</div>
					<div>
						<span className="common-text-bold common-text-bold">350,- €</span><br />zzgl. Verpflegung
					</div>
					<div className="registration-joined-cell">
						<a href={config.GESCHWISTER_ERM}>Geschwisterermäßigung</a><br />
						<a href={config.HEIDELBERG_PASS}>Heidelberg Pass</a><br />
						<a href={config.HEIDELBERG_PASS}>Heidelberg Pass+</a><br />
					</div>
					<div className="registration-page-main-row">
						<span className="registration-text-orange common-text-bold">GT</span> <br />7 - 16 Uhr
					</div>
					<div>
					<span className="common-text-bold common-text-bold">550,- €</span><br />zzgl. Verpflegung
					</div>
				</div>
			</div>
			<div className="common-page-box">
				<h2>Informationen zu Vergünstigungen der Stadt Heidelberg</h2>
				<p>Familien mit einem Wohnsitz in Heidelberg können eine Ermäßigung der Elternbeiträge für die Betreuung Ihres Kindes / Ihrer Kinder beantragen. Dazu stehen Ihnen die folgenden Programme der Stadt Heidelberg zur verfügung:
				</p>
				<ul>
					<ol><Icon name="arrow-right" />&nbsp;&nbsp;<a href={config.GESCHWISTER_ERM}>Geschwisterermäßigung</a></ol>
					<ol><Icon name="arrow-right" />&nbsp;&nbsp;<a href={config.HEIDELBERG_PASS}>Heidelberg Pass</a></ol>
					<ol><Icon name="arrow-right" />&nbsp;&nbsp;<a href={config.HEIDELBERG_PASS}>Heidelberg Pass+</a></ol>
				</ul>
			</div>
			<div className="common-page-box">
				<h2 className="registration-text-green">Sie haben Fragen?</h2>
				<div className="registration-page-grid-box">
					<div className="registration-cell-1 ">
						<Button onClick={onButtonClick}>Kontaktdaten</Button>
					</div>
					<div className="registration-joined-cell-horizontal">
						Dann kontaktieren Sie uns ganz einfach per Email oder rufen Sie uns einfach an. Wir werden uns umgehend bei Ihnen melden.
					</div>
				</div>
			</div>
		</div>
		</>
	);
};

export default RegistrationPage;