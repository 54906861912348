import { ThemeProvider } from '@ui5/webcomponents-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	Navigate,
	RouterProvider,
	createBrowserRouter,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import App from './App';
import ConceptPage from './ui/pages/concept-page';
import ContactPage from './ui/pages/contact-page';
import DataProtectionPage from './ui/pages/dataprotection-page';
import ErrorPage from './ui/pages/error-page';
import Home from './ui/pages/home-page';
import ImpressumPage from './ui/pages/impressum-page';
import KitaPage from './ui/pages/kita-page';
import NewsPage from './ui/pages/news-page';
import RegistrationPage from './ui/pages/registration-page';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorPage />,
		children: [
			{
				path: "/",
				element: <Home />,
				errorElement: <ErrorPage />,
			},
			{
				path: "/kita",
				element: <KitaPage />,
			},
			{
				path: "/concept",
				element: <ConceptPage />,
			},
			{
				path: "/news",
				element: <NewsPage />,
			},
			{
				path: "/registration",
				element: <RegistrationPage />,
			},
			{
				path: "/impressum",
				element: <ImpressumPage />,
			},
			{
				path: "/contact",
				element: <ContactPage />,
			},
			{
				path: "/datenschutzerklaerung",
				element: <DataProtectionPage />,
			},
		],
	},
	{
		basename: "/",
		path: "*",
		element: <Navigate replace to="/" />
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ThemeProvider>
			<RouterProvider router={router} />
		</ThemeProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
