import { Link } from "react-router-dom";
import "../../styles/sectionComponents/title-banner.css";

const TitleBanner = ({ title, subtitle }) => {
	return (
		<div className="title-banner">
			<div className="title-banner-block">
				<h1>{title}</h1>
				{subtitle ? <p>{subtitle}</p> : null}
				<p className="breadcrumbs" aria-label="breadcrumbs">
					<Link to="/">Home</Link > | {title}
				</p>
			</div>
		</div>
	);
};

export default TitleBanner;