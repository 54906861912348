import { Link } from 'react-router-dom';
import '../styles/main-footbar.css';

const Footbar = () => {
	return (
		<div className="App-footer App-footer-bg-1">
			<footer className="footbar-box">
				<span className="footbar-column footbar-column-align-start">&copy; 2024 Entdeckerland gGmbH</span>
				<span className="footbar-column footbar-column-align-end">
					<Link to="/impressum">Impressum</Link>|<a href="/datenschutzerklaerung">Datenschutz</a>
				</span>
			</footer>
		</div>
	);
};

export default Footbar;